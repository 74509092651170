import * as types from "../actions/actionTypes";
import Config from "../config";

const toolsInit = {
    tools: Config.tools,
    widgets: Config.widgets.map(x => {
        return { ...x, toggled: false };
    })
};

export function toolsReducer(state = toolsInit, action) {
    switch (action.type) {
        case types.TOOLS_TOGGLE_WIDGET:
            return {
                ...state,
                widgets: updateArray(state.widgets, action.result)
            };
        default:
            return state;
    }
}

function updateArray(array, item) {
    return array.map(x => {
        if (item.name === x.name) return item;
        return x;
    });
}
