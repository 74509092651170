import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import appStyles from "../../../utils/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Layer from "./layer";
const styles = (theme) => ({
    map: {
        display: "flex",
        alignItems: "center",
        height: 30,
        padding: "8px 20px",
        paddingRight: 0,
        backgroundColor: appStyles.color.LAYER_BACKGROUND,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "whitesmoke"
        },
        "&:not(:last-child)": {
            borderBottom: " 1px whitesmoke solid"
        }
    },
    layerContainer: {},
    layerText: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        textTransform: "capitalize",
        minWidth: 150
    },
    layerCount: {
        marginRight: 8
    },
    squareBtn: {
        borderRadius: 0
    }
});

const toggleLayers = (e, props) => {
    if (e) e.stopPropagation();
    props.onToggleAll(props.map);
};

function Group(props) {
    const { classes, map, zoomLayer, onCollapse, onToggle, moveLayer } = props;

    function renderLayers(map) {
        return map.layers.map((layer, index) => {
            return <Layer layer={layer} map={map} zoomLayer={zoomLayer} onToggle={onToggle} key={index} />;
        });
    }

    return (
        <div key={map.name}>
            <div className={classes.map} onClick={() => onCollapse(map)}>
                <div className="grow">
                    <Typography variant="body1" color="inherit" className={classes.layerText}>
                        {map.name}
                    </Typography>
                </div>
                <Button onClick={(e) => toggleLayers(e, props)}>
                    <Typography className={classes.layerCount}>
                        {map.numberofLayersEnabled || "0"} / {map.layers.length}
                    </Typography>
                </Button>
                <IconButton className={classes.squareBtn}>{map.collapsed ? <ExpandLess /> : <ExpandMore />}</IconButton>
            </div>
            {map.collapsed && <div>{renderLayers(map)}</div>}
        </div>
    );
}

export default withStyles(styles)(Group);
