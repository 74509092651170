import * as types from "./actionTypes";

export function addDrawnFeatures(features) {
    return dispatch => {
        dispatch({ type: types.DRAW_FEATURES_CREATED, result: features });
    };
}

export function removeFeature(feature) {
    return dispatch => {
        dispatch({ type: types.DRAW_FEATURE_DELETED, result: feature });
    };
}

export function renameFeature(feature, newName) {
    let newFeat = { ...feature };
    newFeat.properties.name = newName;
    return dispatch => {
        dispatch({ type: types.DRAW_FEATURES_RENAMED, result: newFeat });
    };
}
