const appStyles = {
    color: {
        CHECKED_SWITCH: "#0393EE",
        HEADER_BACKGROUND: "#024F79",
        CLOSE_COLOR: "#2a385d",
		TOOL_HOVER: "#0C567E",
        LAYER_BACKGROUND: "#F1F3F3",
        CLOSE_BACKGROUND: "#013c5c",
        HEADER_BORDER: "#BDBDBD",
        ACTION_BACKGROUND: "#024F79",
        //temporarily set active action button with same background as the inactive one
        ACTION_ACTIVE: "#0C567E",
        // ACTION_ACTIVE: "#005180"
    },
    fontSize: {
        LIST_TEXT: 14
    }
};
export default appStyles;
