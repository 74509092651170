let mbToken = "pk.eyJ1IjoibGF1dGVjIiwiYSI6ImNrNzRpNjFxaTBpc2IzZnBkNHU3MGJoMmoifQ.yNFawhQiPQGVujuEwb-TIg";

export default {
    apiUrl: "https://api.esox.windgis.io/api/",
    baseLayers: {
        aerial: "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=" + mbToken,
        street: "mapbox://styles/lautec/ck8xficgh3wv71iubmihsufj1"
    },
    sidebarCollapsed: true,
    mapboxToken: mbToken,
    mapZoom: 4.2,
    mapCenter: [6.123906, 53.000635],
    tools: [
        {
            name: "layer-selector",
            namePretty: "Layer selector",
            enabled: true
        },
        {
            name: "search",
            namePretty: "Search",
            enabled: true
        },
        {
            name: "print",
            namePretty: "Print",
            enabled: false
        },
        {
            name: "digitize",
            namePretty: "Digitize",
            enabled: false
        }
    ],
    widgets: [
        {
            name: "measure",
            namePretty: "Measure",
            enabled: true
        }
    ],
    layerSearch: {
        enabled: true,
        datasetId: "b12ee7e8-eb02-4e49-86ba-968324dc8ebd",
        column: "wf_name"
    },
    digitizeTools: [
        {
            name: "point",
            namePretty: "Point",
            icon: "pointIcon",
            enabled: true
        },
        {
            name: "line",
            namePretty: "Line",
            icon: "lineIcon",
            enabled: true
        },
        {
            name: "polygon",
            namePretty: "Polygon",
            icon: "polygonIcon",
            enabled: true
        }
    ]
};
