import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import * as menuActions from "../../../actions/menu";
import * as toolsActions from "../../../actions/tools";
import { measureIcon } from "../../../utils/mapIcons";
import { history } from "../../../store";

import SvgIcon from "@material-ui/core/SvgIcon";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/ArrowBackIos";
import OpenIcon from "@material-ui/icons/ArrowForwardIos";
import LayerIcon from "@material-ui/icons/Layers";
import SearchIcon from "@material-ui/icons/Search";
import DigitizeIcon from "@material-ui/icons/Timeline";
import PrintIcon from "@material-ui/icons/Print";
import Tooltip from "@material-ui/core/Tooltip";
import appStyles from "../../../utils/styles";
import UserMenu from "./userMenu";

const styles = (theme) => ({
    closeButton: {
        color: "white",
        height: 64,
        padding: 8,
        width: "100%",
        backgroundColor: appStyles.color.CLOSE_BACKGROUND,
        borderRadius: 0,

        "&:hover": {
            backgroundColor: appStyles.color.TOOL_HOVER,
            borderRadius: 0
        },
        [theme.breakpoints.down("sm")]: {
            height: 64
        }
    },
    closedMenu: {
        fontSize: "1.5em"
    },
    toolbarBtn: {
        width: 25,
        borderRadius: 0,
        height: 25
    },
    actionButton: {
        backgroundColor: appStyles.color.ACTION_BACKGROUND,
        color: "white",
        width: "100%",
        opacity: 0.7,
        borderRadius: 0,
        borderLeft: "1px solid " + theme.palette.primary,
        padding: 8,
        height: 64,
        "&:hover": {
            borderRadius: 0,
            backgroundColor: appStyles.color.TOOL_HOVER
        }
    },
    actionButtonActive: {
        color: "white",
        backgroundColor: appStyles.color.ACTION_ACTIVE,
        width: "100%",
        padding: 8,
        borderLeft: "1px solid white",
        height: 64,
        borderRadius: 0,
        "&:hover": {
            borderRadius: 0,
            backgroundColor: appStyles.color.TOOL_HOVER
        }
    },
    root: {
        flex: "1 1 auto",
        backgroundColor: "#024F79"
    },
    content: {
        backgroundColor: appStyles.color.ACTION_BACKGROUND,
        height: "100%"
    },
    padding12: {
        backgroundColor: appStyles.color.ACTION_BACKGROUND
    }
});

class ToolsMenu extends Component {
    state = {
        icons: {
            "layer-selector": <LayerIcon fontSize="medium" />,
            search: <SearchIcon fontSize="medium" />,
            print: <PrintIcon fontSize="medium" />,
            measure: <SvgIcon className={this.props.classes.toolbarBtn}>{measureIcon}</SvgIcon>,
            digitize: <DigitizeIcon fontSize="medium" />
        }
    };

    navigate = (location) => {
        history.replace(this.props.match.url + "/" + location);
        if (!this.props.menuState.open) this.props.toggleMenuOpen();
    };

    toggle = (widget) => {
        widget.toggled = !widget.toggled;
        this.props.toggleWidget(widget);
    };

    render() {
        let { classes, location, allTools } = this.props;
        let currentLocaiton = location.pathname.split("/")[2];

        let tools = allTools.tools
            .filter((x) => x.enabled)
            .map((tool, index) => {
                let toolIcon = this.state.icons[tool.name];
                return (
                    <Tooltip key={index} title={tool.namePretty} placement="left">
                        <IconButton
                            onClick={() => this.navigate(tool.name)}
                            className={
                                currentLocaiton === tool.name ? classes.actionButtonActive : classes.actionButton
                            }
                        >
                            {toolIcon}
                        </IconButton>
                    </Tooltip>
                );
            });

        let widgets = allTools.widgets
            .filter((x) => x.enabled)
            .map((widget, index) => {
                let widgetIcon = this.state.icons[widget.name];
                return (
                    <Tooltip key={index} title={widget.namePretty} placement="left">
                        <IconButton
                            onClick={() => this.toggle(widget)}
                            className={widget.toggled ? classes.actionButtonActive : classes.actionButton}
                        >
                            {widgetIcon}
                        </IconButton>
                    </Tooltip>
                );
            });

        return (
            <div className="toolbar">
                <div className={"endAlign " + classes.padding12}>
                    <IconButton
                        className={classes.closeButton + (this.props.menuState.open ? " " + classes.closedMenu : "")}
                        onClick={this.props.toggleMenuOpen}
                        aria-label="Close"
                    >
                        {this.props.menuState.open ? (
                            <CloseIcon style={{ marginLeft: 10 }} />
                        ) : (
                            <OpenIcon style={{ marginLeft: 0 }} />
                        )}
                    </IconButton>
                </div>
                <div className={classes.root}>
                    <div className={classes.content}>
                        {tools}
                        {widgets}
                    </div>
                </div>
                {/* <UserMenu menu={this.props.menuState} userName={this.props.loginState.username} classes={classes} /> */}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loginState: state.login,
        menuState: state.menu,
        allTools: state.tools
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        toggleMenuOpen: () => dispatch(menuActions.toggleOpen()),
        toggleWidget: (widget) => dispatch(toolsActions.toggleWidget(widget))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ToolsMenu)));
