import * as types from "../actions/actionTypes";

const legendInit = {
    visableLayers: {}
};

export function legendReducer(state = legendInit, action) {
    switch (action.type) {
        case types.TOGGLE_LEGEND_LABEL:
            return {
                ...state,
                visableLayers: {
                    ...state.visableLayers,
                    [action.result]: !state.visableLayers[action.result]
                }
            };
        case types.SHOW_LEGEND_LABEL:
            return {
                ...state,
                visableLayers: {
                    ...state.visableLayers,
                    [action.result]: true
                }
            };
        case types.HIDE_LEGEND_LABEL:
            return {
                ...state,
                visableLayers: {
                    ...state.visableLayers,
                    [action.result]: false
                }
            };
        case types.MAP_CLEAR:
            return {
                ...state,
                visableLayers: []
            };
        default:
            return state;
    }
}
