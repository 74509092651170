import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import FilterNone from "@material-ui/icons/GetApp";
import CopyIcon from "@material-ui/icons/FilterNone";
import appStyles from "../../utils/styles";

import * as Utils from "../../utils/infoBoxUtils";
import { Popover } from "@material-ui/core";

const styles = (theme) => ({
    root: {
        backgroundColor: "white",
        width: 350,
        zindex: 4,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",

        [theme.breakpoints.down("sm")]: {
            left: 8,
            bottom: 8,
            right: 8
        }
    },
    header: {
        height: 30,
        minHeight: 30,
        padding: 16,
        paddingLeft: 24,
        zIndex: 8,
        backgroundColor: appStyles.color.CLOSE_BACKGROUND,
        color: "white",
        top: 0
    },
    features: {
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        marginBottom: 8,
        padding: "0px 8px 8px 8px",
        backgroundColor: "white"
    },
    featureHeaderContainer: {
        display: "flex",
        position: "sticky",
        top: 0,
        borderTop: "8px white solid",
        borderBottom: "2px solid " + appStyles.color.HEADER_BORDER,
        marginTop: "-8",
        backgroundColor: "white"
    },
    copyBtn: {
        backgroundColor: "white"
    },
    featureHeader: {
        fontSize: 14,
        fontWeight: "bold",
        padding: 8,
        paddingLeft: 16,
        flexGrow: 1
    },
    propertyKey: {
        paddingLeft: 16,
        padding: 8,
        textTransform: "capitalize",
        fontWeight: "bold",
        fontFamily: ["Roboto", "Helvetica Neue", "sans-serif"],
        textAlign: "left",
        fontSize: 14,
        borderBottom: "whitesmoke 1px solid"
    },
    propertyValue: {
        overflow: "hidden",
        padding: 8,
        fontSize: 14,
        fontWeight: "normal",
        fontFamily: ["Roboto", "Helvetica Neue", "sans-serif"],
        borderBottom: "whitesmoke 1px solid"
    },
    table: {
        tableLayout: "fixed",
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: 4,
        backgroundColor: "white"
    },
    squareBtn: {
        borderRadius: 0,
        color: "black"
    }
});

class EraInfoBox extends Component {
    state = {
        labels: [],
        open: false,
        dialogOpen: false,
        anchorEl: null
    };

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.features) !== JSON.stringify(this.props.features)) {
            this.setState({ open: true });
        }
    }

    onOpenToggle = () => {
        this.setState({ open: false });
    };

    onDownload = (lat, long) => {
        let link = `https://lau-sda.azurewebsites.net/api/download?code=5KThUDySpkOguD6BIKpP820FjObFwBHZ0QuEMHFjELXSWtPdrQBC4A==&location=n${parseFloat(
            lat
        ).toFixed(2)}_e${parseFloat(long).toFixed(2)}&container=esoxera5`;
        window.open(link, "_blank");
    };

    onDownloadMetaOnly = (lat, long) => {
        let link = `https://lau-sda.azurewebsites.net/api/download?code=5KThUDySpkOguD6BIKpP820FjObFwBHZ0QuEMHFjELXSWtPdrQBC4A==&container=esoxera5&location=n${parseFloat(
            lat
        ).toFixed(2)}_e${parseFloat(long).toFixed(2)}&onlyData=true`;
        window.open(link, "_blank");
    };

    oncopyLink = (e, lat, long) => {
        this.setState({
            anchorEl: e.currentTarget,
            dialogOpen: true
        });

        let link = `https://esox.lautec.com/map/?location=${parseFloat(lat).toFixed(2)}/${parseFloat(long).toFixed(
            2
        )}&zoom=8`;

        window.parent.postMessage({ type: "copy-to-clipboard", toCopy: link }, "*");

        //navigator.clipboard.writeText(link);
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            dialogOpen: false
        });
    };

    render() {
        let { classes, layers } = this.props;

        let feature = this.props.features[0];

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <Typography variant="h6">Download</Typography>
                </div>
                <div style={{ padding: "24px", paddingBottom: 8 }}>
                    <Typography variant="subtitle2">
                        Latitude:{" "}
                        <span style={{ float: "right" }}> {parseFloat(feature.properties.lat).toFixed(2)}</span>
                    </Typography>
                    <Typography variant="subtitle2">
                        Longitude:{" "}
                        <span style={{ float: "right" }}> {parseFloat(feature.properties.long).toFixed(2)}</span>
                    </Typography>
                    <Typography variant="subtitle2">
                        Land-sea mask:{" "}
                        <span style={{ float: "right" }}>
                            {parseFloat(feature.properties["land-sea mask"]).toFixed(2)}
                        </span>
                    </Typography>
                    {/* <Typography variant="subtitle2">
                        Interpolated wave data:{" "}
                        <span style={{ float: "right" }}> {feature.properties.interpolated}</span>
                    </Typography> */}
                </div>
                <div style={{ padding: 24, paddingTop: 16 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="subtitle2" style={{ flexGrow: 1 }}>
                            Copy direct link to data point
                        </Typography>
                        <Tooltip key="copyTT" title="Copy link" placement="left">
                            <IconButton
                                onClick={(e) => this.oncopyLink(e, feature.properties.lat, feature.properties.long)}
                                className={classes.squareBtn}
                                size="small"
                            >
                                <CopyIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="subtitle2" style={{ flexGrow: 1 }}>
                            Download metocean data + ESOX tool
                        </Typography>
                        <Tooltip key="copyTT" title="Download" placement="left">
                            <IconButton
                                onClick={() => this.onDownload(feature.properties.lat, feature.properties.long)}
                                className={classes.squareBtn}
                                size="small"
                            >
                                <FilterNone />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="subtitle2" style={{ flexGrow: 1 }}>
                            Download metocean data
                        </Typography>
                        <Tooltip key="copyTT" title="Download" placement="left">
                            <IconButton
                                onClick={() => this.onDownloadMetaOnly(feature.properties.lat, feature.properties.long)}
                                className={classes.squareBtn}
                                size="small"
                            >
                                <FilterNone />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Popover
                        open={this.state.dialogOpen}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center"
                        }}
                    >
                        <Typography className={classes.typography} style={{ padding: 8 }}>
                            Link Copied
                        </Typography>
                    </Popover>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(EraInfoBox);
