import intersect from "@turf/intersect";
import turfCenter from "@turf/center";
import turfPointInPolygon from "@turf/boolean-point-in-polygon";
import turfUnion from "@turf/union";
import turfContains from "@turf/boolean-contains";
import turfDiffrence from "@turf/difference";
import polylabel from "@mapbox/polylabel";

export default class PolygonLabelGenerator {
    constructor(map) {
        this.map = map;
        this.labelData = [];
    }

    removeLayers() {
        for (let i = 0; i < this.labelData.length; i++) {
            let layer = this.labelData[i];
            this.map.removeLayer(layer.layerId);
            this.map.removeSource(layer.layerId + "_centroid");
        }

        this.labelData = [];
    }

    addLayer(layer) {
        let data = {
            layerId: layer.layerId,
            minZoom: layer.minZoom,
            maxZoom: layer.maxZoom,
            textProperty: layer.textProperty,
            geoJson: {
                type: "FeatureCollection",
                features: []
            }
        };

        this.labelData.push(data);

        if (this.map.getSource(layer.layerId + "_centroid")) {
            this.map.removeSource(layer.layerId + "_centroid");
        }

        this.map.addSource(layer.layerId + "_centroid", {
            type: "geojson",
            data: data.geoJson
        });

        this.map.addLayer({
            id: layer.layerId,
            type: "symbol",
            source: layer.layerId + "_centroid",
            minzoom: layer.minZoom,
            maxzoom: layer.maxZoom,
            layout: {
                "text-font": ["Open Sans Regular"]
            }
        });
    }

    generateLabel(layer) {
        let currentZoom = this.map.getZoom();

        if (currentZoom < layer.minZoom || currentZoom > layer.maxZoom) {
            return;
        }

        layer.geoJson.features = [];

        let layerName = layer.layerId.split("_")[0];

        var renderedFeatures = this.map.queryRenderedFeatures({
            layers: [layerName]
        });

        let mapViewBound = this.getViewBoundingBox(this.map);

        //Group features from diffrent tiles
        let groupedFeatures = this.groupFeatures(renderedFeatures, layer.textProperty);

        let visualCenterList = this.findVisualCenters(groupedFeatures, mapViewBound);

        visualCenterList.map(feature => {
            var neighborhoodCenterFeature = {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: feature.geometry.coordinates
                },
                properties: feature.properties
            };
            layer.geoJson.features.push(neighborhoodCenterFeature);
            return feature;
        });
        this.map.getSource(layer.layerId + "_centroid").setData(layer.geoJson);
    }

    generateLabels() {
        for (let i = 0; i < this.labelData.length; i++) {
            let layer = this.labelData[i];
            this.generateLabel(layer);
        }
    }

    getViewBoundingBox(map) {
        let mapSW = map.getBounds()._sw;
        let mapNE = map.getBounds()._ne;

        return {
            type: "Feature",
            geometry: {
                type: "Polygon",
                coordinates: [
                    [
                        [mapSW.lng, mapSW.lat],
                        [mapSW.lng, mapNE.lat],
                        [mapNE.lng, mapNE.lat],
                        [mapNE.lng, mapSW.lat],
                        [mapSW.lng, mapSW.lat]
                    ]
                ]
            },
            properties: {}
        };
    }

    groupFeatures(features, textProperty) {
        return features.reduce((a, b) => {
            if (!a.hasOwnProperty(b.properties[textProperty])) {
                a[b.properties[textProperty]] = [];
            }
            a[b.properties[textProperty]].push(b);
            return a;
        }, {});
    }

    findVisualCenters(groupedFeatures, mapViewBound) {
        var visualCenterList = [];

        for (let features of Object.values(groupedFeatures)) {
            if (features.length > 1) {
                //Union Together polygons from diffrent tiles
                let intersection;
                try {
                    let union = features.reduce((a, b) => {
                        a = turfUnion(a, b);
                        return a;
                    }, features[0]);
                    intersection = intersect(mapViewBound, union);
                } catch (error) {
                    continue;
                }

                if (!intersection) {
                    continue;
                }

                //If union turns into a Multipolygon, make sure that no holes are filled
                if (intersection.geometry.type === "MultiPolygon") {
                    console.log(features[0].properties);
                    intersection = this.removeholes(intersection);
                }

                let ce = this.getVisualCenter(intersection, features[0].properties);
                visualCenterList.push(...ce);
            } else {
                let ce = this.getVisualCenter(features[0], features[0].properties);
                visualCenterList.push(...ce);
            }
        }
        return visualCenterList;
    }

    getVisualCenter(feature, properties) {
        if (feature.geometry.type === "Polygon") {
            var visualCenter = {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: []
                },
                properties: {}
            };

            visualCenter = turfCenter(feature);

            //If the visual center does not fall within the polygon geometry
            let pointInside = turfPointInPolygon(visualCenter.geometry, feature.geometry);
            if (!pointInside) {
                visualCenter.geometry.coordinates = polylabel(feature.geometry.coordinates);
            }

            visualCenter.properties = properties;
            return [visualCenter];
        }
        if (feature.geometry.type === "MultiPolygon") {
            let visualCenters = [];

            feature.geometry.coordinates.forEach(function(coordinate) {
                visualCenters.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: polylabel(coordinate)
                    },
                    properties: properties
                });
            });

            //If the visual center does not fall within the polygon geometry
            for (let i = 0; i < visualCenters.length; i++) {
                let visualCenter = visualCenters[i];
                let pointInside = turfPointInPolygon(visualCenter.geometry, feature.geometry);
                if (!pointInside) {
                    visualCenters[i].geometry.coordinates = polylabel(feature.geometry.coordinates[i]);
                }
            }

            return visualCenters;
        }
    }

    removeholes(union) {
        let InitialGeometry = {
            type: "Feature",
            geometry: {
                type: "Polygon",
                coordinates: union.geometry.coordinates[0]
            },
            properties: {}
        };

        let validGeomtries = [];

        for (let i = 1; i < union.geometry.coordinates.length; i++) {
            let polygon = union.geometry.coordinates[i];

            let possibleHole = {
                type: "Feature",
                geometry: {
                    type: "Polygon",
                    coordinates: polygon
                },
                properties: {}
            };

            if (!turfContains(InitialGeometry, possibleHole)) {
                validGeomtries.push(union.geometry.coordinates[i]);
            } else {
                InitialGeometry = turfDiffrence(InitialGeometry, possibleHole);
            }
        }

        if (validGeomtries.length > 0) {
            return {
                type: "Feature",
                geometry: {
                    type: "MultiPolygon",
                    coordinates: [[InitialGeometry.geometry.coordinates[0]], ...validGeomtries]
                },
                properties: {}
            };
        } else {
            return InitialGeometry;
        }
    }
}
