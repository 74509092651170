import * as types from "../actions/actionTypes";

const mapInit = {
    layers: [],
    paints: [],
    layouts: [],
    onClick: null,
    clickPos: { lat: 0, lng: 0 },
    measuring: false,
    loaded: false,
    zoom: 3,
    measureDist: 0,
    flyTo: { x: 0, y: 0 },
    fitBounds: { bbox: [], options: {} },
};

export function mapReducer(state = mapInit, action) {
    switch (action.type) {
        case types.MAP_ADD_LAYER:
            return {
                ...state,
                layers: [...state.layers, action.result]
            };
        case types.MAP_ADD_LAYERS:
            return {
                ...state,
                layers: [...state.layers, ...action.result]
            };
        case types.MAP_UPDATE_LAYER:
            return {
                ...state,
                layers: updateArray(state.layers, action.result),
                paints: removeFromArray(state.paints, action.result),
                layouts: removeFromArray(state.layouts, action.result)
            };
        case types.MAP_REMOVE_LAYER:
            return {
                ...state,
                zoom: 2,
                layers: removeFromArray(state.layers, action.result)
            };
        case types.MAP_ADD_PAINT:
            return {
                ...state,
                paints: [...state.paints, action.result]
            };
        case types.MAP_ADD_PAINTS:
            return {
                ...state,
                paints: [...state.paints, ...action.result]
            };
        case types.MAP_UPDATE_PAINT:
            return {
                ...state,
                paints: updateArray(state.paints, action.result)
            };
        case types.MAP_ADD_LAYOUT:
            return {
                ...state,
                layouts: [...state.layouts, action.result]
            };
        case types.MAP_ADD_LAYOUTS:
            return {
                ...state,
                layouts: [...state.layouts, ...action.result]
            };
        case types.MAP_UPDATE_LAYOUT:
            return {
                ...state,
                layouts: updateArray(state.layouts, action.result)
            };
        case types.MAP_FLY_TO:
            return {
                ...state,
                flyTo: action.result
            };
        case types.MAP_CLEAR:
            return {
                ...state,
                layouts: [],
                paints: [],
                layers: []
            };
        case types.MAP_TOGGLE_MEASURE:
            return {
                ...state,
                measuring: action.result,
                measureDist: 0
            };
        case types.MAP_SET_MEASUREDIST:
            return {
                ...state,
                measureDist: action.result
            };
        case types.MAP_LOADED:
            return {
                ...state,
                loaded: true
            };
        case types.MAP_CHANGE_CLICK:
            return {
                ...state,
                onClick: action.result
            };
        case types.MAP_CLICK:
            return {
                ...state,
                clickPos: action.result
            };
        case types.MAP_FIT_BOUNDS:
            return {
                ...state,
                fitBounds: action.result
            }; 
        default:
            return state;
    }
}

function updateArray(array, item) {
    return array.map(x => {
        if (item.layerId === x.layerId) return item;
        return x;
    });
}

function removeFromArray(array, item) {
    return array.filter(x => item.layerId !== x.layerId);
}
