import * as types from "../actions/actionTypes";

const digitizeInit = {
    features: []
};

export function digitizeReducer(state = digitizeInit, action) {
    switch (action.type) {
        case types.DRAW_FEATURES_CREATED:
            return {
                ...state,
                features: [...state.features, ...action.result]
            };
        case types.DRAW_FEATURE_DELETED:
            return {
                ...state,
                features: removeFeatureFromArray(state.features, action.result)
            };
        case types.DRAW_FEATURES_RENAMED:
            return {
                ...state,
                features: updateFeatureInArray(state.features, action.result)
            };

        default:
            return state;
    }
}

function removeFeatureFromArray(features, item) {
    return features.filter((feature) => feature.id !== item.id);
}

function updateFeatureInArray(features, item) {
    let result = features.map((feature) => (feature.id === item.id ? item : feature));
    return result;
}
