import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import appStyles from "../../../utils/styles";
import IconButton from "@material-ui/core/IconButton";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import InfoIcon from "@material-ui/icons/Info";
import { Tooltip } from "@material-ui/core";

import Context from "./contextMenu";
import { Icon } from "@material-ui/core";

const styles = (theme) => ({
    layer: {
        display: "flex",
        alignItems: "center",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingRight: "0px",
        paddingLeft: "20px",
        height: 30,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "whitesmoke"
        }
    },
    squareBtn: {
        borderRadius: 0
    },
    grow: {
        flexGrow: 1
    },
    grayIcon: {
        color: "rgba(0, 0, 0, 0.54)",
        paddingLeft: 5
    },
    switch: {
        marginLeft: -12,
        marginRight: 8
    },
    layerText: {
        fontSize: appStyles.fontSize.LIST_TEXT,
        textOverflow: "ellipsis",
        overflow: "hidden"
    }
});

function Layer(props) {
    const [contextOpen, setContextOpen] = useState(false);
    const anchorRef = React.useRef(null);

    const toggleCMenu = (e) => {
        if (e) e.stopPropagation();
        setContextOpen((contextOpen) => !contextOpen);
        return null;
    };

    const closeCMenu = () => {
        setContextOpen(false);
    };

    const { classes, map, layer, onToggle, zoomLayer } = props;

    return (
        <div>
            <div className={classes.layer} onClick={() => onToggle(map, layer)}>
                <Switch
                    color="inherit"
                    className={classes.switch}
                    edge="end"
                    checked={layer.visable}
                    // disabled={!layer.isShown}
                    inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
                />
                <Typography variant="body1" color="inherit" className={classes.layerText}>
                    {layer.name}
                </Typography>
                {/* {!layer.isShown && <Tooltip  title="Layer is not visible at current zoom level."> 
                                        <Icon className={classes.grayIcon}>
                                            <InfoIcon />
                                        </Icon>
                                    </Tooltip> } */}
                <span className={classes.grow}></span>
                <IconButton
                    className={classes.squareBtn}
                    ref={anchorRef}
                    aria-controls={contextOpen ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={toggleCMenu}
                >
                    <MoreHoriz />
                </IconButton>
            </div>
            <Context
                anchorEl={anchorRef.current}
                closeCMenu={closeCMenu}
                onToggle={onToggle}
                map={map}
                layer={layer}
                open={contextOpen}
                toggleCMenu={toggleCMenu}
                zoomLayer={zoomLayer}
            />
        </div>
    );
}

export default withStyles(styles)(Layer);
