import * as types from "./actionTypes";
import axiosClient from "./apiClient";
import conf from "../config";

let map_data = [
    {
        name: "Layers",
        permissionType: 3,
        datasets: [
            {
                id: "ee7c1eae-916f-4500-9404-c0aa0a1222e3",
                name: "Wind turbine generator",
                minZoom: 1,
                maxZoom: 10,
                columns: null,
                columnsToShow: null,
                rowCount: 0,
                databaseSize: null,
                createdUtc: "0001-01-01T00:00:00+00:00",
                modifiedUtc: "0001-01-01T00:00:00+00:00",
                geometryType: "POINT",
                cached: true,
                bounds: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-71.53764999999999, 24.690999999999995],
                            [-71.53764999999999, 58.32251666999997],
                            [120.8348611, 58.32251666999997],
                            [120.8348611, 24.690999999999995],
                            [-71.53764999999999, 24.690999999999995]
                        ]
                    ]
                }
            },
            {
                id: "4b5a7084-f77c-4fa1-a8bd-9a66a81f544f",
                name: "Lautec Global Offices",
                minZoom: 1,
                maxZoom: 10,
                columns: null,
                columnsToShow: null,
                rowCount: 0,
                databaseSize: null,
                createdUtc: "0001-01-01T00:00:00+00:00",
                modifiedUtc: "0001-01-01T00:00:00+00:00",
                geometryType: "POINT",
                cached: true,
                bounds: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-71.07513894741457, -37.81406738960363],
                            [-71.07513894741457, 55.705362],
                            [144.97197450682907, 55.705362],
                            [144.97197450682907, -37.81406738960363],
                            [-71.07513894741457, -37.81406738960363]
                        ]
                    ]
                }
            },
            {
                id: "beaad957-f218-452e-88fc-eac912bef98b",
                name: "Era5 Final",
                minZoom: 1,
                maxZoom: 10,
                columns: null,
                columnsToShow: null,
                rowCount: 0,
                databaseSize: null,
                createdUtc: "0001-01-01T00:00:00+00:00",
                modifiedUtc: "0001-01-01T00:00:00+00:00",
                geometryType: "POINT",
                cached: true,
                bounds: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-179.75000000000003, -59.99999999999999],
                            [-179.75000000000003, 69.99999999999999],
                            [179.75000000000003, 69.99999999999999],
                            [179.75000000000003, -59.99999999999999],
                            [-179.75000000000003, -59.99999999999999]
                        ]
                    ]
                }
            },
            {
                id: "b12ee7e8-eb02-4e49-86ba-968324dc8ebd",
                name: "Wind Farms Esox Updated",
                minZoom: 1,
                maxZoom: 10,
                columns: null,
                columnsToShow: null,
                rowCount: 0,
                databaseSize: null,
                createdUtc: "0001-01-01T00:00:00+00:00",
                modifiedUtc: "0001-01-01T00:00:00+00:00",
                geometryType: "MULTIPOLYGON",
                cached: true,
                bounds: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-158.66360644068465, -38.91748200000001],
                            [-158.66360644068465, 71.0456353404247],
                            [147.15159499999996, 71.0456353404247],
                            [147.15159499999996, -38.91748200000001],
                            [-158.66360644068465, -38.91748200000001]
                        ]
                    ]
                }
            }
        ],
        id: "3e99c234-f311-4d38-a613-f42023fd22f8",
        bounds: {
            type: "Polygon",
            coordinates: [
                [
                    [-179.75000000000003, -59.99999999999999],
                    [-179.75000000000003, 71.0456353404247],
                    [179.75000000000003, 71.0456353404247],
                    [179.75000000000003, -59.99999999999999],
                    [-179.75000000000003, -59.99999999999999]
                ]
            ]
        },
        minZoom: 1,
        maxZoom: 10,
        cached: true
    },
    {
        name: "Global Data",
        permissionType: 3,
        datasets: [
            {
                id: "9fd7e883-796a-449a-b6bc-1886b9dff8cc",
                name: "Economic Zones",
                minZoom: 1,
                maxZoom: 7,
                columns: null,
                columnsToShow: null,
                rowCount: 0,
                databaseSize: null,
                createdUtc: "0001-01-01T00:00:00+00:00",
                modifiedUtc: "0001-01-01T00:00:00+00:00",
                geometryType: "MULTILINESTRING",
                cached: true,
                bounds: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-180.0, -76.8001174201828],
                            [-180.0, 86.99400535037381],
                            [180.0000000000002, 86.99400535037381],
                            [180.0000000000002, -76.8001174201828],
                            [-180.0, -76.8001174201828]
                        ]
                    ]
                }
            },
            {
                id: "c8ae64ff-b909-485d-aa79-785c3ab4ac21",
                name: "Gebco 2019 Contours",
                minZoom: 1,
                maxZoom: 7,
                columns: null,
                columnsToShow: null,
                rowCount: 0,
                databaseSize: null,
                createdUtc: "0001-01-01T00:00:00+00:00",
                modifiedUtc: "0001-01-01T00:00:00+00:00",
                geometryType: "LINESTRING",
                cached: true,
                bounds: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [-180.0, -78.65881986027946],
                            [-180.0, 84.20232843137255],
                            [180.0, 84.20232843137255],
                            [180.0, -78.65881986027946],
                            [-180.0, -78.65881986027946]
                        ]
                    ]
                }
            }
        ],
        id: "1c0d135e-6910-46a5-ba09-1d0b29207fec",
        bounds: {
            type: "Polygon",
            coordinates: [
                [
                    [-180.0, -78.65881986027946],
                    [-180.0, 86.99400535037381],
                    [180.0000000000002, 86.99400535037381],
                    [180.0000000000002, -78.65881986027946],
                    [-180.0, -78.65881986027946]
                ]
            ]
        },
        minZoom: 1,
        maxZoom: 7,
        cached: true
    }
];

export function getMaps() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_MAPS });

        return new Promise((resolutionFunc, rejectionFunc) => {
            resolutionFunc({
                type: types.FETCH_MAPS_COMPLETE,
                result: map_data
            });
        });

        return dispatch({
            type: types.FETCH_MAPS_COMPLETE,
            result: map_data
        });

        return axiosClient.get("map").then(function (res) {
            return dispatch({
                type: types.FETCH_MAPS_COMPLETE,
                result: res.data
            });
        });
    };
}

export function getDatasetGeojson(datasetId) {
    return (dispatch) => {
        dispatch({ type: "" });

        return axiosClient.get(`dataset/public/${datasetId}/geojson`).then(function (res) {
            return dispatch({
                type: "",
                result: res.data
            });
        });
    };
}

export function getStyles(mapId) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_STYLES });

        return axiosClient.get(`map/public/${mapId}/style`).then(function (res) {
            return dispatch({
                type: types.FETCH_STYLES_COMPLETE,
                result: res.data,
                mapId: mapId
            });
        });
    };
}

export function getMultipleStyles(mapIds) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_MULTIPLE_STYLES });

        let promises = mapIds.map((x) => axiosClient.get(`map/public/${x}/style`));

        return Promise.all(promises).then(function (res) {
            return dispatch({
                type: types.FETCH_MULTIPLE_STYLES_COMPLETE,
                result: res.map((x) => x.data)
            });
        });
    };
}

export function setStyles(style) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_STYLES_COMPLETE, result: style });
    };
}
