import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Route } from "react-router-dom";
import * as mapActions from "../../actions/map";
import * as serverActions from "../../actions/atlasClient";
import * as layerSelectorActions from "../../actions/layerSelector";
import * as legendActions from "../../actions/legend";

import Config from "../../config";

import Menu from "./layerSelector/layerSelector";

import Print from "./print/print";
import Digitize from "./digitize/digitize";

import Search from "./search/search";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

const styles = (theme) => ({
    root: {
        // marginTop: -64 + 'px',
        transition: "all 0.4s ",
        top: 16,
        zIndex: 10,
        [theme.breakpoints.down("xs")]: {
            right: 4,
            left: 4,
            maxWidth: 450
        }
    },
    appBar: {
        boxShadow: "none"
    },
    content: {
        fontSize: 20
    },
    logo: {
        height: 30
    },
    toolbar: {
        backgroundColor: "white",
        alignItems: "center",
        display: "grid",
        padding: "0px 16px",
        minHeight: 50,
        height: 64
    }
});

class SidebarRoot extends Component {
    state = {
        tools: {
            "layer-selector": Menu,
            //change these to the correct components after implementing
            search: Search,
            print: Print,
            digitize: Digitize
        },
        enabledTools: Config.tools
    };

    componentDidMount() {
        if (this.props.mapState.loaded) {
            this.loadMaps();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mapState.loaded !== this.props.mapState.loaded) {
            this.loadMaps();
        }
    }

    loadMaps() {
        this.props.getMaps().then((mapResponse) => {
            let mapsMap = {};

            for (let i = 0; i < mapResponse.result.length; i++) {
                let map = mapResponse.result[i];
                mapsMap[map.id] = map;
            }

            this.props
                .getMultipleStyles(mapResponse.result.map((x) => x.id))
                .then((res) => this.loadStyles(res, mapsMap));
        });
    }

    loadStyles(stylesResponse, mapsMap) {
        let maps = [];
        let layers = [];
        let paints = [];
        let layouts = [];

        let order = {
            "Wind Farm Area": 3,
            "Wind Turbine Generator": 2,
            "Metocean Data Point": 1,
            "Lautec Offices": 0,
            "Economic Zones": 4,
            "Gebco 2019 Contours": 5
        };

        let enabled = ["Wind Farms Esox Updated", "Wind turbine generator", "Era5 Final"];

        for (let i = 0; i < stylesResponse.result.length; i++) {
            let styleObject = stylesResponse.result[i][0];
            let map = mapsMap[styleObject.mapId];
            map.layers = [];
            map.checked = false;
            map.collapsed = true;

            if (!map.cached) {
                continue;
            }

            let datasetsMap = map.datasets.reduce((object, dataset) => {
                object[dataset.name] = dataset;
                return object;
            }, {});

            let groupedStyles = styleObject.style.reduce((object, style, index) => {
                (object[style.sourceName] = object[style.sourceName] || []).push({ ...style, index: index });
                return object;
            }, {});

            for (const datasetName in groupedStyles) {
                let styles = groupedStyles[datasetName];

                for (let i = 0; i < styles.length; i++) {
                    let style = styles[i];
                    let dataset = datasetsMap[style.sourceName];

                    let visable = enabled.includes(style.sourceName);

                    let layerId = styleObject.id + ":" + style.sourceName + ":" + i;
                    let layer = {
                        name: style.name,
                        sourceId: map.id,
                        layerId: layerId,
                        sourceName: dataset.name,
                        type: style.type,
                        isShown:
                            Math.floor(style.minZoom) >= Math.floor(Config.mapZoom) &&
                            Math.floor(Config.mapZoom) <= Math.floor(style.maxZoom),
                        sourceMinZoom: map.minZoom,
                        sourceMaxZoom: map.maxZoom,
                        minZoom: style.minZoom,
                        maxZoom: style.maxZoom,
                        visable: visable,
                        bounds: dataset.bounds,
                        index: map.datasets.findIndex((x) => x.name === dataset.name)
                    };

                    layers.push(layer);

                    if (visable) {
                        this.props.showLegendLabel(layerId);
                    }

                    if (i === 0) {
                        layer.numberOfLayers = styles.length;

                        if (maps.length == 0) {
                            map.layers.push(layer);
                        } else {
                            maps.find((x) => x.name === "Layers").layers.push(layer);
                        }
                    }

                    paints.push({
                        layerId: layerId,
                        properties: style.paint
                    });

                    layouts.push({
                        layerId: layerId,
                        properties: [...style.layout, { name: "visibility", value: visable ? "visible" : "none" }]
                    });
                }
            }

            if (map.name === "Layers") {
                maps.push(map);
            }

            // maps.push(map);
        }

        maps[0].layers.sort((a, b) => {
            return order[a.name] - order[b.name];
        });

        layers.sort((a, b) => {
            return order[a.name] - order[b.name];
        });

        this.props.setLayerGroups(maps);

        this.props.addMapLayers(layers);
        this.props.addMapPaints(paints);
        this.props.addMapLayouts(layouts);
    }

    render() {
        let { match, params, classes } = this.props;

        let routes = this.state.enabledTools
            .filter((x) => x.enabled)
            .map((tool, index) => {
                let toolComponent = this.state.tools[tool.name];

                if (toolComponent === undefined) {
                    throw Error("Invalid tool name: " + tool.name);
                }

                return <Route exact path={`${match.url}/${tool.name}`} component={toolComponent} key={index} />;
            });

        return (
            <div className={this.props.menu.open ? "sidebar" : "sidebar hide"}>
                {/* <AppBar position="static" color="primary" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <img src="/media/Windgis-logo.svg" className={classes.logo} alt="Logo"></img>
                    </Toolbar>
                </AppBar> */}
                {routes}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        menu: state.menu,
        mapState: state.map
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMaps: () => dispatch(serverActions.getMaps()),
        getMultipleStyles: (mapIds) => dispatch(serverActions.getMultipleStyles(mapIds)),
        addMapLayers: (layers) => dispatch(mapActions.addLayers(layers)),
        showLegendLabel: (labelName) => dispatch(legendActions.showLabel(labelName)),
        addMapPaints: (paints) => dispatch(mapActions.addPaints(paints)),
        addMapLayouts: (layouts) => dispatch(mapActions.addLayouts(layouts)),
        setLayerGroups: (groups) => dispatch(layerSelectorActions.setLayerGroups(groups))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SidebarRoot));
