import { combineReducers } from "redux";
import { mapReducer } from "./map";
import { styleReducer } from "./style";
import { legendReducer } from "./legend";
import { infoBoxReducer } from "./infoBox";
import { layerSelectorReducer } from "./layerSelector";
import { authReducer } from "./auth";
import { toolsReducer } from "./tools";
import { digitizeReducer } from "./digitize";
import { menuReducer } from "./menu";
import { loginReducer } from "./login";

// main reducers
const reducers = combineReducers({
    map: mapReducer,
    login: loginReducer,
    style: styleReducer,
    legend: legendReducer,
    infoBox: infoBoxReducer,
    layerSelector: layerSelectorReducer,
    menu: menuReducer,
    tools: toolsReducer,
    auth: authReducer,
    digitize: digitizeReducer
});

export default reducers;
