import * as types from "../actions/actionTypes";

const styleInit = {
    fetching: false,
    styles: []
};

export function styleReducer(state = styleInit, action) {
    switch (action.type) {
        case types.FETCH_STYLES:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_STYLES_COMPLETE:
            return {
                ...state,
                fetching: false,
                styles: [...state.styles, ...action.result]
            };
        case types.FETCH_MULTIPLE_STYLES_COMPLETE:
            return {
                ...state,
                fetching: false,
                styles: action.result
            };
        default:
            return state;
    }
}
