import appStyles from "./utils/styles";

const primaryColor = "#024F79";
const secondaryColor = "rgb(156, 177, 230)";

export const MuiConfig = {
    typography: {
        useNextVariants: true,
        fontFamily: ["Roboto", '"Helvetica Neue"', "sans-serif"]
    },
    palette: {
        primary: {
            main: primaryColor
        },
        secondary: {
            main: secondaryColor
        }
    },
    overrides: {
        MuiMenu: {
            paper: {
                "-webkit-box-shadow": "0px 0px 1px 0px rgba(209,209,209,0.2)",
                "-moz-box-shadow": "0px 0px 1px 0px rgba(209,209,209,0.2)",
                "box-shadow": "0px 0px 1px 0px rgba(209,209,209,0.2)"
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: 14
            }
        },
        MuiCardContent: {
            root: {
                "&:last-child": {
                    paddingBottom: 0
                }
            }
        },
        MuiTabs: {
            root: {
                backgroundColor: "whitesmoke",
                color: "black"
            },
            indicator: {
                backgroundColor: "rgb(56, 82, 77)"
            }
        },
        MuiTab: {
            root: {
                fontWeight: "bold",
                "&$selected": {
                    backgroundColor: "#e4e4e4",
                    color: "black",
                    fontSize: 14
                },
                "&:hover": {
                    backgroundColor: "#eae7e7"
                }
            }
        },
        MuiAutocomplete: {
            root: {
                paddingRight: 6,
                paddingLeft: 6,
                "& .MuiFormControl-root": {
                    marginTop: 6,
                    marginBottom: 6,
                    borderRadius: 5
                }
            }
        },
        MuiSwitch: {
            switchBase: {
                "&$checked": {
                    color: appStyles.color.CHECKED_SWITCH
                },
                "&$checked + $track": {
                    backgroundColor: appStyles.color.CHECKED_SWITCH
                },
                "&$disabled + $track": {
                    backgroundColor: "black"
                }
            }
        }
    }
};
