import * as types from "./actionTypes";

export function updateLayer(layer) {
    return dispatch => {
        dispatch({ type: types.MAP_UPDATE_LAYER, result: layer });
    };
}

export function addLayer(layer) {
    return dispatch => {
        dispatch({ type: types.MAP_ADD_LAYER, result: layer });
    };
}

export function addLayers(layers) {
    return dispatch => {
        dispatch({ type: types.MAP_ADD_LAYERS, result: layers });
    };
}

export function removeLayer(layer) {
    return dispatch => {
        dispatch({ type: types.MAP_REMOVE_LAYER, result: layer });
    };
}

export function toggleMeasuring(measuring) {
    return dispatch => {
        dispatch({ type: types.MAP_TOGGLE_MEASURE, result: measuring });
    };
}
export function setmeasureDist(distance) {
    return dispatch => {
        dispatch({ type: types.MAP_SET_MEASUREDIST, result: distance });
    };
}

export function setLayerType(type) {
    return dispatch => {
        dispatch({ type: types.MAP_SET_LAYER_TYPE, result: type });
    };
}

export function addPaint(paint) {
    return dispatch => {
        dispatch({ type: types.MAP_ADD_PAINT, result: paint });
    };
}

export function addPaints(paints) {
    return dispatch => {
        dispatch({ type: types.MAP_ADD_PAINTS, result: paints });
    };
}

export function updatePaint(paint) {
    return dispatch => {
        dispatch({ type: types.MAP_UPDATE_PAINT, result: paint });
    };
}

export function addLayout(layout) {
    return dispatch => {
        dispatch({ type: types.MAP_ADD_LAYOUT, result: layout });
    };
}

export function addLayouts(layouts) {
    return dispatch => {
        dispatch({ type: types.MAP_ADD_LAYOUTS, result: layouts });
    };
}

export function updateLayout(layout) {
    return dispatch => {
        dispatch({ type: types.MAP_UPDATE_LAYOUT, result: layout });
    };
}

export function flyTo(location) {
    return dispatch => {
        dispatch({ type: types.MAP_FLY_TO, result: location });
    };
}

export function fitBounds(bbox,options) {
    return dispatch => {
        dispatch({ type: types.MAP_FIT_BOUNDS, result:{bbox,options} });
    }
}

export function clear() {
    return dispatch => {
        dispatch({ type: types.MAP_CLEAR });
    };
}

export function selectFeatures(features) {
    return dispatch => {
        dispatch({ type: types.SELECT_FEATURES, result: features });
    };
}

export function mapLoaded() {
    return dispatch => {
        dispatch({ type: types.MAP_LOADED });
    };
}

export function zoomEnd(zoom) {
    return dispatch => {
        dispatch({ type: types.MAP_ZOOMEND, result: zoom });
    };
}

export function mapChangeClick(func) {
    return dispatch => {
        dispatch({ type: types.MAP_CHANGE_CLICK, result: func });
    };
}

export function mapClick(position) {
    return dispatch => {
        dispatch({ type: types.MAP_CLICK, result: position });
    };
}