export const AUTHENTICATING = "AUTHENTICATING";
export const AUTHENTICATED = "AUTHENTICATED";
export const AUTHENTICATING_FAILED = "AUTHENTICATING_FAILED";

export const DRAW_FEATURES_CREATED = "DRAW_FEATURES_CREATED";
export const DRAW_FEATURE_DELETED = "DRAW_FEATURE_DELETED";
export const DRAW_FEATURES_RENAMED = "DRAW_FEATURES_RENAMED";

export const MAP_LOADED = "MAP_LOADED";
export const MAP_CLICK = "MAP_CLICK";
export const MAP_CHANGE_CLICK = "MAP_CHANGE_CLICK";
export const MAP_UPDATE_LAYER = "MAP_UPDATE_LAYER";
export const MAP_ADD_LAYER = "MAP_ADD_LAYER";
export const MAP_ADD_LAYERS = "MAP_ADD_LAYERS";
export const MAP_REMOVE_LAYER = "MAP_REMOVE_LAYER";
export const MAP_UPDATE_PAINT = "MAP_UPDATE_PAINT";
export const MAP_ADD_PAINT = "MAP_ADD_PAINT";
export const MAP_ADD_PAINTS = "MAP_ADD_PAINTS";
export const MAP_UPDATE_LAYOUT = "MAP_UPDATE_LAYOUT";
export const MAP_TOGGLE_MEASURE = "MAP_TOGGLE_MEASURE";
export const MAP_SET_MEASUREDIST = "MAP_SET_MEASUREDIST";
export const MAP_FIT_BOUNDS = "MAP_FIT_BOUNDS";

export const MAP_ADD_LAYOUT = "MAP_ADD_LAYOUT";
export const MAP_ADD_LAYOUTS = "MAP_ADD_LAYOUTS";
export const MAP_SET_LAYER_TYPE = "MAP_SET_LAYER_TYPE";
export const MAP_FLY_TO = "MAP_FLY_TO";
export const MAP_CLEAR = "MAP_CLEAR";
export const MAP_ZOOMEND = "MAP_ZOOMEND";

export const SELECT_FEATURES = "SELECT_FEATURES";

export const SET_LAYER_GROUPS = "SET_LAYER_GROUPS";
export const SET_SEARCH = "SET_SEARCH";

export const FETCH_MAPS = "FETCH_MAPS";
export const FETCH_MAPS_COMPLETE = "FETCH_MAPS_COMPLETE";
export const FETCH_MAP_CAPABILITIES = "FETCH_MAP_CAPABILITIES";
export const FETCH_MAP_CAPABILITIES_COMPLETE = "FETCH_MAP_CAPABILITIES_COMPLETE";

export const FETCH_STYLES = "FETCH_STYLES";
export const FETCH_STYLES_COMPLETE = "FETCH_STYLES_COMPLETE";
export const FETCH_MULTIPLE_STYLES = "FETCH_MULTIPLE_STYLES";
export const FETCH_MULTIPLE_STYLES_COMPLETE = "FETCH_MULTIPLE_STYLES_COMPLETE";

export const TOGGLE_LEGEND_LABEL = "TOGGLE_LEGEND_LABEL";
export const SHOW_LEGEND_LABEL = "SHOW_LEGEND_LABEL";
export const HIDE_LEGEND_LABEL = "HIDE_LEGEND_LABEL";

export const TOGGLE_MENU_OPEN = "TOGGLE_MENU_OPEN";

export const TOOLS_TOGGLE_WIDGET = "TOOLS_TOGGLE_WIDGET";
