import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import CopyIcon from "@material-ui/icons/FileCopy";

const styles = (theme) => ({
    root: {
        position: "fixed",
        width: 29,
        height: 29,
        top: 226,
        right: 10,
        textAlign: "center",
        fontSize: "14px",
        fontWeight: "bold",
        borderRadius: 4,
        cursor: "pointer",
        display: "table",
        zIndex: 1,
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    icon: {
        fontSize: 18
    }
});

const ToggleBaseLayer = (props) => {
    let { classes } = props;
    return (
        <Tooltip key="toggleTT" title="Copy current location" placement="left">
            <div className={classes.root} onClick={props.onClick}>
                <CopyIcon className={classes.icon} />
            </div>
        </Tooltip>
    );
};

export default withStyles(styles)(ToggleBaseLayer);
