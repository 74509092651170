import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import FilterNone from "@material-ui/icons/CloudDownload";
import appStyles from "../../utils/styles";

import * as Utils from "../../utils/infoBoxUtils";

const styles = (theme) => ({
    root: {
        backgroundColor: "white",
        zindex: 4,
        borderRadius: 5,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            left: 8,
            bottom: 8,
            right: 8
        }
    },
    header: {
        height: 30,
        minHeight: 30,
        zIndex: 8,
        backgroundColor: appStyles.color.CLOSE_BACKGROUND,
        color: "white",
        top: 0
    },
    features: {
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        marginBottom: 8,
        padding: "0px 8px 8px 8px",
        backgroundColor: "white"
    },
    featureHeaderContainer: {
        display: "flex",
        position: "sticky",
        top: 0,
        borderTop: "8px white solid",
        borderBottom: "2px solid " + appStyles.color.HEADER_BORDER,
        marginTop: "-8",
        backgroundColor: "white"
    },
    copyBtn: {
        backgroundColor: "white"
    },
    featureHeader: {
        fontSize: 14,
        fontWeight: "bold",
        padding: 8,
        paddingLeft: 16,
        flexGrow: 1
    },
    propertyKey: {
        paddingLeft: 16,
        padding: 8,
        textTransform: "capitalize",
        fontWeight: "bold",
        fontFamily: ["Roboto", "Helvetica Neue", "sans-serif"],
        textAlign: "left",
        fontSize: 14,
        borderBottom: "whitesmoke 1px solid"
    },
    propertyValue: {
        overflow: "hidden",
        padding: 8,
        fontSize: 14,
        fontWeight: "normal",
        fontFamily: ["Roboto", "Helvetica Neue", "sans-serif"],
        borderBottom: "whitesmoke 1px solid"
    },
    table: {
        tableLayout: "fixed",
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: 4,
        backgroundColor: "white"
    },
    squareBtn: {
        borderRadius: 0,
        paddingTop: 0,
        paddingBottom: 0,
        color: "black"
    }
});

class InfoBox extends Component {
    state = {
        labels: [],
        open: false
    };

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.infoBoxState.features) !== JSON.stringify(this.props.infoBoxState.features)) {
            this.setState({ open: true });
        }
    }

    onOpenToggle = () => {
        this.setState({ open: false });
    };

    onDownload = (lat, long) => {
        let link = `https://lau-sda.azurewebsites.net/api/download?code=5KThUDySpkOguD6BIKpP820FjObFwBHZ0QuEMHFjELXSWtPdrQBC4A==&location=n${lat.toFixed(
            2
        )}_e${long.toFixed(2)}&container=esoxera5`;
        window.open(link, "_blank");
    };

    onCopyClick = (index) => {
        let properties = Object.keys(this.props.features[index].properties).reduce((a, b) => {
            a.push({
                key: b,
                value: this.props.features[index].properties[b]
            });
            return a;
        }, []);
        let theClipboard = navigator.clipboard;
        if (theClipboard) {
            let copyText = "";
            properties.map((propKeyValue, propIndex) => {
                let value;
                if (
                    ["ogc_fid", "geom_type", "?column?"].indexOf(propKeyValue.key) >= 0 ||
                    propKeyValue.key.includes("link") ||
                    propKeyValue.key.includes("foto")
                ) {
                    return false;
                }

                // Creating links
                if (propKeyValue.key.includes("dato")) {
                    let date = new Date(propKeyValue.value);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1; // january is 0
                    let yyyy = date.getFullYear();
                    value = `${dd}/${mm}-${yyyy}`;
                } else {
                    value = propKeyValue.value;
                }

                if (propIndex >= 1) copyText += "\n";
                copyText += propKeyValue.key.charAt(0).toUpperCase() + propKeyValue.key.slice(1) + ": " + value;
                return false;
            });
            navigator.clipboard.writeText(copyText);
        }
    };

    render() {
        let { classes, layers } = this.props;
        let layerNames = {};
        for (let lyrNr in layers) {
            layerNames[layers[lyrNr].sourceName] = layers[lyrNr].name;
        }
        let features = this.props.features.map((feature, featureIndex) => {
            //let attributeSort = feature.sorting

            let properties;

            let propertiesEls = Utils.BuildProperties(feature, classes);

            return (
                <div className={classes.feature} key={featureIndex}>
                    <div className={classes.featureHeaderContainer}>
                        <Typography variant="body1" className={classes.featureHeader}>
                            {layerNames[feature.sourceLayer]}
                        </Typography>
                        {/* <Tooltip key="copyTT" title="Download" placement="left">
                            <IconButton
                                onClick={() => this.onDownload(feature.properties.lat, feature.properties.long)}
                                className={classes.squareBtn + " " + classes.copyBtn}
                            >
                                <FilterNone />
                            </IconButton>
                        </Tooltip> */}
                    </div>

                    <table className={classes.table}>
                        <tbody>{propertiesEls}</tbody>
                    </table>
                </div>
            );
        });

        return (
            <div className={classes.root}>
                <div className={classes.header}></div>
                <div className={classes.features}>{features}</div>
            </div>
        );
    }
}

export default withStyles(styles)(InfoBox);
