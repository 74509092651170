import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { getMap } from "../../map/map";
import { Divider, Button, Typography } from "@material-ui/core";

const styles = (theme) => ({
    text: {
        padding: 16,
        textAlign: "center"
    },
    image: {
        padding: "8px 20px"
    },
    btnRight: {
        padding: "8px 20px",
        textAlign: "right"
    },
    visible: { width: "100%", height: "100%" },
    hidden: { width: 0, height: 0 },
    container: {
        padding: 16
    }
});

class Print extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null
        };
    }

    onPrintScrn = (e) => {
        this.map = getMap();
        let img = new Image();
        let mapCanvas = this.map.getCanvas(document.querySelector(".mapboxgl-canvas"));
        img.src = mapCanvas.toDataURL();
        this.setState({ image: img.src });
    };

    render() {
        let { classes } = this.props;
        return (
            <div>
                <Divider />
                <div className={classes.container}>
                    <Typography variant="h6">Print Map</Typography>
                </div>
                <Divider />
                <div className={classes.text}>
                    <Button variant="outlined" color="primary" onClick={this.onPrintScrn}>
                        Screenshot
                    </Button>
                </div>
                {this.state.image && (
                    <div>
                        <div className={classes.image}>
                            <img
                                src={this.state.image ? this.state.image : ""}
                                alt="Screenshot"
                                className={this.state.image ? classes.visible : classes.hidden}
                            ></img>
                        </div>
                        <div className={classes.btnRight}>
                            <Button
                                variant="outlined"
                                color="primary"
                                download="map.png"
                                href={`data:image/${this.state.image}`}
                            >
                                Download
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(Print);
