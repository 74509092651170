import React, { Component } from "react";
import { kml } from "@tmcw/togeojson";
import tokml from "tokml";
import { withStyles } from "@material-ui/core/styles";
import {
    MenuItem,
    InputBase,
    IconButton,
    SvgIcon,
    Typography,
    Tooltip,
    Divider,
    Button,
    Menu
} from "@material-ui/core";
import * as mapActions from "../../../actions/map";
import * as digitizeActions from "../../../actions/digitize";
import { digitizeIcons } from "../../../utils/mapIcons";
import { connect } from "react-redux";
import { getMap, getDraw } from "../../map/map";
import Config from "../../../config";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TrashIcon from "@material-ui/icons/Delete";
import Download from "downloadjs";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";

const StyledMenu = withStyles((theme) => ({
    paper: {
        border: "2px solid " + theme.palette.primary.main,
        borderTop: "none",
        borderRadius: "0px 0px 5px 5px"
    },
    list: {
        padding: 0
    }
}))((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        fullWidth
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontWeight: "bold",
        color: "rgba(0,0,0,0.87)",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "white"
        }
    }
}))((props) => <MenuItem {...props} />);

const styles = (theme) => ({
    root: {
        Width: 350,
        position: "relative",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        overflow: "auto"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        padding: 16
    },
    dropOverlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.3)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pointerEvents: "none"
    },
    title: {
        padding: 16
    },
    digitizeTools: {
        display: "flex",
        padding: "4px 8px",
        justifyContent: "space-between"
    },
    buttonIcon: {
        height: 24,
        width: 20,
        color: "rgba(51, 51, 51, 1)"
    },
    toolButton: {
        width: "32%",
        display: "flex"
    },
    toolButton50: {
        width: "49%",
        display: "flex"
    },
    toolButton50SpaceBetween: {
        width: "49%",
        display: "flex",
        justifyContent: "space-between"
    },
    content: {
        padding: "0px 16px",
        display: "flex",
        flexDirection: "column",
        overflow: "auto"
    },
    selectMsg: {
        padding: "8px 0px",
        height: 30
    },
    features: {
        display: "flex",
        flexDirection: "column",
        overflow: "auto"
    },
    feature: {
        padding: 8,
        height: 30,
        display: "flex",
        alignItems: "center",
        backgroundColor: "whitesmoke",
        marginBottom: 4
    },
    selectedFeature: {
        padding: 8,
        display: "flex",
        height: 30,
        alignItems: "center",
        backgroundColor: "lightgray",
        marginBottom: 4
    },
    featureType: {
        flexGrow: 1,
        userSelect: "none"
    },
    helpText: {
        marginTop: 16,
        marginBottom: 8
    },
    titleContainer: {
        padding: 16
    }
});

var modes = MapboxDraw.modes;
modes.static = StaticMode;

class Digitize extends Component {
    state = {
        selectedFeatures: [],
        loaded: false,
        isDroppingFile: false,
        downloadMenuAnchor: null
    };

    componentDidMount() {
        if (this.props.mapState.loaded && !this.state.loaded) {
            this.setState({ loaded: true });
            this.init();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mapState.loaded !== this.props.mapState.loaded && !this.state.loaded) {
            this.init();
            this.setState({ loaded: true });
        }
    }

    init() {
        this.map = getMap();
        this.map.on("draw.create", this.onFeatureCreated);
        this.map.on("draw.selectionchange", this.onSelectionChanged);
        this.draw = getDraw();
        this.draw.changeMode("simple_select");
        this.featureIndex = this.props.digitizeState.features.length;

        this.lastOnClickHandler = this.props.mapState.onClick;
        this.props.mapChangeClick(() => {});
    }

    componentWillUnmount() {
        this.draw.changeMode("static");
        this.map.off("draw.create", this.onFeatureCreated);
        this.map.off("draw.selectionchange", this.onSelectionChanged);
        this.props.mapChangeClick(this.lastOnClickHandler);
    }

    onFeatureCreated = (e) => {
        this.setState({
            selectedFeatures: e.features
        });

        this.props.addDrawnFeatures(e.features);
    };

    onSelectionChanged = (e) => {
        this.setState({
            selectedFeatures: e.features
        });
    };

    onDraw = (type) => {
        switch (type) {
            case "point":
                this.draw.changeMode("draw_point");
                break;
            case "line":
                this.draw.changeMode("draw_line_string");
                break;
            case "polygon":
                this.draw.changeMode("draw_polygon");
                break;
            default:
                return null;
        }
    };

    onFeatureClick = (e, feature) => {
        if (e.shiftKey) {
            this.setState({
                selectedFeatures: [...this.state.selectedFeatures, feature]
            });
            this.draw.changeMode("simple_select", {
                featureIds: [...this.state.selectedFeatures.map((x) => x.id), feature.id]
            });
        } else {
            this.setState({
                selectedFeatures: [feature]
            });
            this.draw.changeMode("simple_select", { featureIds: [feature.id] });
        }
    };

    onFeatureDelete = (e, feature) => {
        e.stopPropagation();
        this.draw.delete(feature.id);
        this.props.removeFeature(feature);
    };

    onDragOver = (e) => {
        e.preventDefault();
        this.setState({ isDroppingFile: true });
    };

    onDragLeave = (e) => {
        e.preventDefault();
        this.setState({ isDroppingFile: false });
    };

    onFileDrop = (e) => {
        e.preventDefault();
        this.setState({ isDroppingFile: false });

        let files = Object.values(e.dataTransfer.files).reduce((array, file) => {
            array.push(file);
            return array;
        }, []);

        this.files = e.dataTransfer.files;

        this.loadFile(files[0]);
    };

    onFileSelected = (e) => {
        let files = Object.values(e.target.files).reduce((array, file) => {
            array.push(file);
            return array;
        }, []);

        this.files = e.target.files;

        this.loadFile(files[0]);
    };

    loadFile = (file) => {
        new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.onload = function (theFile) {
                resolve(reader.result);
            };
            reader.readAsText(file);
        }).then((res) => {
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(res, "text/xml");
            let geoJson = kml(xmlDoc);

            geoJson.features = geoJson.features.map((x) => {
                return { ...x, id: (this.featureIndex++).toString() };
            });

            this.draw.add(geoJson);
            this.props.addDrawnFeatures(geoJson.features);
        });
    };

    downloadGeoJson = () => {
        let geoJson = this.draw.getAll();

        Download(JSON.stringify(geoJson), "drawn-objects.geojson");
    };

    downloadKml = () => {
        let geoJson = this.draw.getAll();

        Download(tokml(geoJson), "drawn-objects.kml");
    };

    onOpenDownloadMenu = (e) => {
        this.setState({
            downloadMenuAnchor: e.currentTarget
        });
    };

    onDownloadMenuClose = () => {
        this.setState({
            downloadMenuAnchor: null
        });
    };

    updateFeatureName = (e) => {
        let featureId = this.state.selectedFeatures[0].id;
        this.draw.setFeatureProperty(featureId, "name", e.target.value);
        this.props.renameFeature(this.state.selectedFeatures[0], e.target.value);
    };

    render() {
        let { classes } = this.props;

        let features = this.props.digitizeState.features.map((feature, index) => {
            let className = this.state.selectedFeatures.some((x) => x.id === feature.id)
                ? classes.selectedFeature
                : classes.feature;
            return (
                <div className={className} key={"feat" + index} onClick={(e) => this.onFeatureClick(e, feature)}>
                    {/* onBlur is also called when feature name does not change and might rename wrong feature so use onChange */}
                    {/* <InputBase
                        className={classes.featureType}
                        defaultValue={feature.properties.name}
                        inputProps={{ "aria-label": "naked" }}
                        onChange={(e) => this.updateFeatureName(e)}
                    /> */}
                    <Typography variant="body2" style={{ flexGrow: 1 }}>
                        {feature.geometry.type}
                    </Typography>
                    <IconButton onClick={(e) => this.onFeatureDelete(e, feature)}>
                        <TrashIcon />
                    </IconButton>
                </div>
            );
        });

        let buttons = Config.digitizeTools.map((tool, index) => {
            return (
                <Tooltip key={index} title={tool.namePretty}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.toolButton}
                        onClick={() => this.onDraw(tool.name)}
                    >
                        <SvgIcon className={classes.buttonIcon}>{digitizeIcons[tool.icon]}</SvgIcon>
                    </Button>
                </Tooltip>
            );
        });

        return (
            <div
                className={classes.root}
                onDrop={this.onFileDrop}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
            >
                <Divider />
                <div className={classes.title}>
                    <Typography variant="h6">Digitize</Typography>
                </div>
                <Divider />
                {this.state.isDroppingFile && (
                    <div className={classes.dropOverlay}>
                        <Typography variant="title2">Drop File</Typography>
                    </div>
                )}
                <div className={classes.container}>
                    <div className={classes.digitizeTools}>{buttons}</div>
                    <div className={classes.digitizeTools}>
                        <Button
                            variant="outlined"
                            fullWidth
                            className={classes.toolButton50}
                            color="primary"
                            onClick={() => {
                                document.getElementById("raised-button-file").click();
                            }}
                        >
                            Upload
                        </Button>
                        <input
                            accept=".kml"
                            className={classes.input}
                            style={{ display: "none" }}
                            id="raised-button-file"
                            type="file"
                            onChange={this.onFileSelected}
                        />
                        <Button
                            variant="outlined"
                            fullWidth
                            className={classes.toolButton50SpaceBetween}
                            color="primary"
                            onClick={this.onOpenDownloadMenu}
                            aria-haspopup="true"
                        >
                            Download
                            <ExpandMoreIcon />
                        </Button>
                        <StyledMenu
                            anchorEl={this.state.downloadMenuAnchor}
                            open={Boolean(this.state.downloadMenuAnchor)}
                            onClose={this.onDownloadMenuClose}
                        >
                            <StyledMenuItem onClick={this.downloadGeoJson}>GEOJSON</StyledMenuItem>
                            <StyledMenuItem onClick={this.downloadKml}>KML</StyledMenuItem>
                        </StyledMenu>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.helpText}>
                            {this.props.digitizeState.features.length > 0 && (
                                <Typography variant="caption">Hold "Shift" to select multiple</Typography>
                            )}
                        </div>
                        <div className={classes.features}>{features}</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        digitizeState: state.digitize,
        mapState: state.map
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        addDrawnFeatures: (features) => dispatch(digitizeActions.addDrawnFeatures(features)),
        removeFeature: (feature) => dispatch(digitizeActions.removeFeature(feature)),
        renameFeature: (feature, name) => dispatch(digitizeActions.renameFeature(feature, name)),
        addMapPaint: (paint) => dispatch(mapActions.addPaint(paint)),
        mapChangeClick: (func) => dispatch(mapActions.mapChangeClick(func))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Digitize));
