import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import * as mapActions from "../../../actions/map";
import { getMap } from "../../map/map";
import * as legendActions from "../../../actions/legend";
import * as layerSelectorActions from "../../../actions/layerSelector";
import * as menuActions from "../../../actions/menu";

import { IconButton, LinearProgress, InputAdornment, Input, Divider, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import appStyles from "../../../utils/styles";

import Layer from "./layer";
import Group from "./group";

const styles = (theme) => ({
    root: {
        transition: "all 0.4s ",
        zIndex: 10,
        height: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            right: 4,
            left: 4,
            maxWidth: 450
        }
    },
    searchContainer: {
        padding: 16
    },
    paper: {
        marginTop: 8,
        "&:not:first-child": {
            marginTop: 8
        }
    },
    map: {
        display: "flex",
        alignItems: "center",
        padding: 8,
        backgroundColor: appStyles.color.LAYER_BACKGROUND,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "whitesmoke"
        },
        "&:not(:last-child)": {
            borderBottom: " 1px whitesmoke solid"
        }
    },
    layerInput: { fontSize: 14 },
    layer: {
        display: "flex",
        alignItems: "center",
        padding: 8,

        cursor: "pointer",
        "&:hover": {
            backgroundColor: "whitesmoke"
        },
        "&:not(:last-child)": {
            borderBottom: " 1px whitesmoke solid"
        }
    },
    layerHeader: {
        padding: 16,
        borderBottom: " 1px whitesmoke solid",
        borderTop: " 1px whitesmoke solid"
    },
    layers: {
        height: "100%",
        overflow: "auto",
        [theme.breakpoints.down("sm")]: {
            maxHeight: "100%"
        }
    },
    squareBtn: {
        borderRadius: 0
    },
    noPadButton: {
        padding: 0,
        background: "#F3F3F3"
    }
});

class LayerSelector extends Component {
    state = {
        maps: [],
        baseLayer: "Map",
        search: "",
        fetching: false
    };

    componentDidMount() {
        if (this.props.mapState.loaded) {
            this.init();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mapState.loaded !== this.props.mapState.loaded) {
            this.init();
        }
    }

    init() {
        this.map = getMap();
    }

    updateUrl() {
        let currentUrl = this.props.history.location.pathname;
        let urlSplit = currentUrl.split("/");

        urlSplit[urlSplit.length - 1] = this.state.layers
            .filter((layer) => layer.visable)
            .map((layer) => layer.name)
            .join(".");

        if (urlSplit[urlSplit.length - 1] === "") {
            urlSplit[urlSplit.length - 1] = "none";
        }

        this.props.history.replace(urlSplit.join("/"));
    }

    handleCollapse = (map) => {
        map.collapsed = !map.collapsed;

        this.setState({
            maps: this.state.maps.map((x) => {
                if (x.id === map.id) {
                    return {
                        ...map
                    };
                }
                return x;
            })
        });
    };

    handleToggleAll = (map) => {
        let displayLayers;
        //turn all layers off only if all are on
        displayLayers = map.layers.filter((layer) => !layer.visable).length === 0 ? "none" : "visible";

        map.layers = map.layers.map((lyr) => {
            return {
                ...lyr,
                visable: displayLayers === "visible"
            };
        });

        map.numberofLayersEnabled = map.layers.filter((lyr) => lyr.visable).length;

        this.setState({
            maps: this.state.maps.map((amap) => {
                if (amap.id === map.id) {
                    return {
                        ...map
                    };
                }
                return amap;
            })
        });

        //process legend
        for (let layerIndex in map.layers) {
            let layer = map.layers[layerIndex];
            let layerIdSplit = layer.layerId.split(":");

            for (let i = 0; i < layer.numberOfLayers; i++) {
                let layerId = `${layerIdSplit[0]}:${layerIdSplit[1]}:${i}`;
                this.props.changeMapLayout({
                    layerId: layerId,
                    properties: [
                        {
                            name: "visibility",
                            value: displayLayers
                        }
                    ]
                });
            }

            //hide all layers if all are shown, show all layers otherwise
            if (displayLayers === "none") this.props.hideLegendLabel(map.layers[layerIndex].layerId);
            else this.props.showLegendLabel(map.layers[layerIndex].layerId);
        }
    };

    handleToggle = (map, layer) => {
        let layerDisplay = !layer.visable ? "visible" : "none";

        map.layers = map.layers.map((lyr) => {
            if (lyr.name === layer.name) {
                return {
                    ...lyr,
                    visable: layerDisplay === "visible"
                };
            }
            return lyr;
        });

        map.numberofLayersEnabled = map.layers.filter((lyr) => lyr.visable).length;

        this.setState({
            maps: this.state.maps.map((x) => {
                if (x.id === map.id) {
                    return {
                        ...map
                    };
                }
                return x;
            })
        });
        let layerIdSplit = layer.layerId.split(":");

        for (let i = 0; i < layer.numberOfLayers; i++) {
            let layerId = `${layerIdSplit[0]}:${layerIdSplit[1]}:${i}`;
            this.props.changeMapLayout({
                layerId: layerId,
                properties: [
                    {
                        name: "visibility",
                        value: layerDisplay
                    }
                ]
            });
        }

        this.props.toggleLegendLabel(layer.layerId);
    };

    onSearch = (e) => {
        this.setState({
            search: e.target.value
        });
    };

    clearSearch = () => {
        this.setState({
            search: ""
        });
    };

    zoomLayer = (map, layer) => {
        if (layer.bounds && !layer.visable) {
            this.handleToggle(map, layer);
            layer.visable = true;
        }
        if (layer.bounds && layer.visable) {
            let bounds = [layer.bounds.coordinates[0][0], layer.bounds.coordinates[0][2]];
            this.map.fitBounds(bounds, { padding: 100 });
        }
    };

    moveLayer = (result) => {
        const { destination, source } = result;
        if (!destination || !source) return;
        if (!(destination.droppableId === source.droppableId)) return;
        let layerGroups = Object.assign([], this.props.layerState.layerGroups);
        layerGroups.forEach((group) => {
            if (group.name === destination.droppableId) {
                //move element in layers array to new position only in same group
                group.layers.splice(destination.index, 0, group.layers.splice(source.index, 1)[0]);
            }
        });
        this.props.setLayerGroups(layerGroups);
    };

    render() {
        let { classes, layerState } = this.props;
        let maps = "";

        if (this.state.search === "") {
            maps = layerState.layerGroups.map((map, index) => {
                return (
                    <Group
                        map={map}
                        key={index}
                        moveLayer={this.moveLayer}
                        zoomLayer={this.zoomLayer}
                        onToggleAll={this.handleToggleAll}
                        onToggle={this.handleToggle}
                        onCollapse={this.handleCollapse}
                    />
                );
            });
        } else {
            maps = layerState.layerGroups.map((map, index) => {
                let layers = map.layers.filter((layer) =>
                    layer.name.toLowerCase().includes(this.state.search.toLowerCase())
                );
                return (
                    <div key={index}>
                        {layers.map((layer, layerIndex) => {
                            return (
                                <Layer
                                    layer={layer}
                                    isShown={layer.isShown}
                                    zoomLayer={this.zoomLayer}
                                    map={map}
                                    onToggle={this.handleToggle}
                                    key={layerIndex}
                                />
                            );
                        })}
                    </div>
                );
            });
        }

        return (
            <div className={classes.root}>
                <div className={classes.searchContainer}>
                    <Typography variant="h6">Layers</Typography>
                </div>
                <Divider />
                <div className={classes.searchContainer}>
                    <Input
                        id="full-width"
                        inputProps={{ autoComplete: "off" }}
                        placeholder="Search Layers..."
                        value={this.state.search}
                        onChange={this.onSearch}
                        fullWidth
                        className={classes.layerInput}
                        endAdornment={
                            <InputAdornment position="end">
                                {this.state.search !== "" && (
                                    <IconButton
                                        className={classes.noPadButton + " " + classes.squareBtn}
                                        aria-label="toggle password visibility"
                                        onClick={this.clearSearch}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        }
                    />
                </div>
                <div className={classes.layers} id="layer-menu">
                    {this.props.layerState.fetching && <LinearProgress />}
                    {maps}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        layerState: state.layerSelector,
        mapState: state.map
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeMapLayout: (layout) => dispatch(mapActions.updateLayout(layout)),
        hideLegendLabel: (layerId) => dispatch(legendActions.hideLabel(layerId)),
        showLegendLabel: (layerId) => dispatch(legendActions.showLabel(layerId)),
        toggleLegendLabel: (layerId) => dispatch(legendActions.toggleLabel(layerId)),
        toggleMenuOpen: () => dispatch(menuActions.toggleOpen()),
        setLayerGroups: (groups) => dispatch(layerSelectorActions.setLayerGroups(groups))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LayerSelector));
