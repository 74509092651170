import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import * as mapActions from "../../../actions/map";
import { connect } from "react-redux";
import { getMap } from "../../map/map";
import turfLength from "@turf/length";
import turfArea from "@turf/area";
import FilterNone from "@material-ui/icons/FilterNone";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { v4 as uuidv4 } from "uuid";
import * as FeatureFactory from "../../../utils/geojsonFeatureFactory";
import { Typography, Tooltip, Button, Menu } from "@material-ui/core";

let timeout;

const styles = (theme) => ({
    root: {
        position: "absolute",
        top: 16,
        left: 16,
        zIndex: 2,
        backgroundColor: "white",
        minWidth: 300
    },
    header: {
        padding: 8,
        paddingLeft: 16,
        display: "flex",
        alignItems: "center",
        backgroundColor: "whitesmoke"
    },
    headerText: {
        flexGrow: 1,
        fontFamily: ["Roboto", '"Helvetica Neue"', "sans-serif"]
    },
    content: {
        paddingTop: 20,
        paddingRight: 8,
        paddingBottom: 16,
        paddingLeft: 16
    },
    copyBtn: {
        padding: 5,
        minWidth: 0
    },
    arrowIcon: {
        marginLeft: 8
    },
    infoRow: {
        display: "flex",
        paddingBottom: 10
    },
    infoRowSpan: {
        flexGrow: 1
    },
    measurementUnit: {
        justifyContent: "left",
        paddingLeft: 10,
        paddingRight: 0
    },
    measurementValue: {
        fontFamily: ["Roboto", '"Helvetica Neue"', "sans-serif"],
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 1.75,
        textTransform: "uppercase",
        paddingRight: 4,
        alignItems: "center",
        display: "inline-flex"
    }
});
// GeoJSON object to hold our measurement features
const pointLayer = {
    type: "FeatureCollection",
    features: []
};

// Used to draw a line between points
let lineStringfeature = {
    type: "Feature",
    geometry: {
        type: "LineString",
        coordinates: []
    }
};

let lineLayer = {
    type: "FeatureCollection",
    features: [lineStringfeature]
};

let units = [
    { multiply: 1, name: "km", namePretty: "Kilometers" },
    { multiply: 1000, name: "m", namePretty: "Meters" },
    { multiply: 0.621371, name: "mi", namePretty: "Miles" },
    {
        multiply: 0.539957,
        name: "nmi",
        namePretty: "Nautical Miles"
    }
];

let areaUnits = [
    { multiply: 1, name: "m²", namePretty: "Square Meters" },
    { multiply: 0.000001, name: "km²", namePretty: "Square Kilometers" },
    { multiply: 0.621371, name: "mi", namePretty: "Miles" },
    {
        multiply: 0.000000291545189,
        name: "nmi²",
        namePretty: "Square Nautical Miles"
    }
];

class Measure extends Component {
    state = {
        distance: 0,
        perimeter: 0,
        area: 0,
        drawing: true,
        selected: null,
        unitMenuAnchorEl: null,
        areaUnitMenuAnchorEl: null,
        units: units,
        areaUnits: areaUnits,
        selectedUnit: { multiply: 1, name: "km", namePretty: "Kilometers" },
        selectedAreaUnit: { multiply: 0.000001, name: "km²", namePretty: "Square Kilometers" }
    };

    componentDidMount() {
        if (this.props.mapState.loaded) {
            this.init();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mapState.loaded !== this.props.mapState.loaded) {
            this.init();
        }
    }

    init() {
        this.map = getMap();
        this.lastOnClickHandler = this.props.mapState.onClick;
        this.props.mapChangeClick(this.onDrawClick);

        this.map.on("mousedown", "measure-points", this.onMapMouseDown);
        this.map.on("touchstart", "measure-points", this.onMapMouseDown);

        this.map.on("mousemove", this.onMapMouseMove);
        this.map.on("touchmove", this.onMapMouseMove);

        this.map.on("mousemove", "measure-points", this.onMapPointsMouseMove);
        this.map.on("touchmove", "measure-points", this.onMapMouseMove);

        this.map.on("mouseleave", "measure-points", this.onMapPointsMouseLeave);
        this.map.on("touchend", "measure-points", this.onMapMouseUp);
        this.map.on("touchcancel", "measure-points", this.onMapMouseUp);
        this.map.on("mouseup", "measure-points", this.onMapMouseUp);

        this.index = 0;
        this.map.getCanvas().style.cursor = "crosshair";

        this.map.addSource("measure-points", {
            type: "geojson",
            data: pointLayer
        });

        this.map.addSource("measure-lines", {
            type: "geojson",
            data: lineLayer
        });

        this.map.addLayer({
            id: "measure-lines",
            type: "line",
            source: "measure-lines",
            layout: {
                "line-cap": "round",
                "line-join": "round"
            },
            paint: {
                "line-color": "#024F79",
                "line-width": 2.5
            }
        });
        this.map.addLayer({
            id: "measure-points",
            type: "circle",
            source: "measure-points",
            paint: {
                "circle-radius": 6,
                "circle-stroke-width": 2,
                "circle-color": ["case", ["boolean", ["feature-state", "hover"], false], "white", "#024F79"],
                "circle-stroke-color": ["case", ["boolean", ["feature-state", "hover"], false], "#024F79", "white"]
            }
        });
    }

    componentWillUnmount() {
        this.map.removeLayer("measure-points");
        this.map.removeLayer("measure-lines");
        this.map.removeSource("measure-points");
        this.map.removeSource("measure-lines");

        lineStringfeature = {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: []
            }
        };
        lineLayer.features = [lineStringfeature];
        pointLayer.features = [];

        this.map.off("mousedown", "measure-points", this.onMapMouseDown);
        this.map.off("mousemove", this.onMapMouseMove);
        this.map.off("mousemove", "measure-points", this.onMapPointsMouseMove);
        this.map.off("mouseleave", "measure-points", this.onMapPointsMouseLeave);
        this.map.off("mouseup", "measure-points", this.onMapMouseUp);

        this.map.getCanvas().style.cursor = "";

        this.props.mapChangeClick(this.lastOnClickHandler);
    }

    calculatePerimeter(polygon) {
        let distance = 0;
        for (let i = 0; i < polygon.geometry.coordinates[0].length - 1; i++) {
            let point = polygon.geometry.coordinates[0][i];
            let point2 = polygon.geometry.coordinates[0][i + 1];

            let line = FeatureFactory.toLine([point, point2], {});

            distance += turfLength(line);
        }
        return distance;
    }

    onMapMouseDown = (e) => {
        var msFeatures = this.map.queryRenderedFeatures(e.point, {
            layers: ["measure-points"]
        });

        //If no features where hit
        if (msFeatures.length === 0) {
            return;
        }

        //Prevent default map mousedown event
        e.preventDefault();

        this.map.setFeatureState({ source: "measure-points", id: msFeatures[0].id }, { hover: true });

        //Get a referece to the selected feature in pointlayer
        let feature = pointLayer.features.find((x) => x.properties.id === msFeatures[0].properties.id);

        this.setState({
            selected: feature
        });
    };

    onMapMouseUp = (e) => {
        //Dont do anything if nothing is selected
        if (!this.state.selected) {
            return;
        }

        this.map.setFeatureState({ source: "measure-points", id: this.state.selected.id }, { hover: false });

        if (this.state.isPolygon) {
            this.setState({
                selected: null,
                perimeter: this.calculatePerimeter(lineStringfeature),
                area: turfArea(lineStringfeature)
            });
        } else {
            this.setState({
                selected: null,
                distance: turfLength(lineStringfeature)
            });
        }
    };

    onMapMouseMove = (e) => {
        if (timeout) clearTimeout(timeout);

        if (this.state.drawing) {
            timeout = setTimeout(() => {
                let feature = {
                    type: lineStringfeature.type,
                    geometry: {
                        type: lineStringfeature.geometry.type,
                        coordinates: [...lineStringfeature.geometry.coordinates, [e.lngLat.lng, e.lngLat.lat]]
                    }
                };
                this.setState({ distance: turfLength(feature) });
                timeout = null;
            }, 25);
        }

        if (!this.state.selected) {
            return;
        }

        //Move point
        this.state.selected.geometry.coordinates = [e.lngLat.lng, e.lngLat.lat];

        //Handle moving linestring
        if (lineStringfeature.geometry.type === "LineString") {
            lineStringfeature.geometry.coordinates[this.state.selected.properties.index] = [e.lngLat.lng, e.lngLat.lat];
        } else {
            //If the first point in the polygon is selected, move both the first and last point
            if (this.state.selected.properties.index === 0) {
                lineStringfeature.geometry.coordinates[0][0] = [e.lngLat.lng, e.lngLat.lat];
                lineStringfeature.geometry.coordinates[0][lineStringfeature.geometry.coordinates[0].length - 1] = [
                    e.lngLat.lng,
                    e.lngLat.lat
                ];
            } else {
                lineStringfeature.geometry.coordinates[0][this.state.selected.properties.index] = [
                    e.lngLat.lng,
                    e.lngLat.lat
                ];
            }
        }

        this.map.getSource("measure-points").setData(pointLayer);
        this.map.getSource("measure-lines").setData(lineLayer);
    };

    onMapPointsMouseMove = (e) => {
        this.map.getCanvas().style.cursor = this.state.drawing ? "crosshair" : "pointer";
    };

    onMapPointsMouseLeave = (e) => {
        this.map.getCanvas().style.cursor = this.state.drawing ? "crosshair" : "";
    };

    drawingFinished(endPoint) {
        //If the first point is clicked, convert to a polygon. Else continue as line
        if (endPoint.properties.index === 0) {
            var polygon = FeatureFactory.lineToPolygon(lineStringfeature.geometry.coordinates, {});
            lineStringfeature = polygon;
            lineLayer = {
                type: "FeatureCollection",
                features: [lineStringfeature]
            };
            this.map.getSource("measure-lines").setData(lineLayer);
            this.setState({
                perimeter: this.calculatePerimeter(lineStringfeature),
                area: turfArea(lineStringfeature),
                isPolygon: true,
                drawing: false
            });
        } else {
            this.setState({
                distance: turfLength(lineStringfeature),
                drawing: false
            });
        }

        this.map.getCanvas().style.cursor = "";
        this.props.mapChangeClick(this.lastOnClickHandler);
    }

    onDrawSelect = (e) => {};

    onDrawClick = (e) => {
        var msFeatures = this.map.queryRenderedFeatures(e.point, {
            layers: ["measure-points"]
        });
        e.preventDefault();

        //If a previous point is clicked, and more than one point has been drawn
        if (msFeatures.length > 0 && pointLayer.features.length > 1) {
            this.drawingFinished(msFeatures[0]);
            return;
        }

        let newId = this.index++;

        let newPoint = FeatureFactory.toPoint(e.lngLat, { id: newId, index: newId }, newId);

        pointLayer.features.push(newPoint);
        //add the new point as the new end for the line
        lineStringfeature.geometry.coordinates.push(newPoint.geometry.coordinates);

        this.map.getSource("measure-points").setData(pointLayer);
        this.map.getSource("measure-lines").setData(lineLayer);

        this.map.setFeatureState({ source: "measure-points", id: newId }, { hover: false });

        this.setState({
            distance: turfLength(lineStringfeature)
        });
    };

    onCopyValue = (text) => {
        window.parent.postMessage({ type: "copy-to-clipboard", toCopy: text }, "*");
        //navigator.clipboard.writeText(text);
    };

    onOpenUnitMenu = (e) => {
        this.setState({
            unitMenuAnchorEl: e.currentTarget
        });
    };

    onCloseUnitMenu = (e) => {
        this.setState({
            unitMenuAnchorEl: null
        });
    };

    onOpenAreaUnitMenu = (e) => {
        this.setState({
            areaUnitMenuAnchorEl: e.currentTarget
        });
    };

    onCloseAreaUnitMenu = (e) => {
        this.setState({
            areaUnitMenuAnchorEl: null
        });
    };

    getLengthUnitFormatted(distance) {
        let roundedDistance = (distance * this.state.selectedUnit.multiply).toFixed(2);
        return { value: roundedDistance, unit: this.state.selectedUnit.name };
    }

    getAreaUnitFormatted(area) {
        let roundedArea = (area * this.state.selectedAreaUnit.multiply).toFixed(2);
        return { value: roundedArea, unit: this.state.selectedAreaUnit.name };
    }

    onUnitItemClick = (unit) => {
        this.setState({
            selectedUnit: unit,
            unitMenuAnchorEl: null
        });
    };

    onAreaUnitItemClick = (unit) => {
        this.setState({
            selectedAreaUnit: unit,
            areaUnitMenuAnchorEl: null
        });
    };

    onStartNewMeasure = () => {
        this.props.mapChangeClick(this.onDrawClick);

        lineStringfeature = {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: []
            }
        };
        lineLayer.features = [lineStringfeature];
        pointLayer.features = [];

        this.setState({
            distance: 0,
            perimeter: 0,
            area: 0,
            drawing: true,
            selected: null,
            isPolygon: false
        });

        this.index = 0;
        this.map.getCanvas().style.cursor = "crosshair";

        this.map.getSource("measure-points").setData(pointLayer);
        this.map.getSource("measure-lines").setData(lineLayer);
    };

    onFinishMeasureButtonClicked = () => {
        //Finish drawing, and select the last drawn point as the final
        this.drawingFinished(pointLayer.features[pointLayer.features.length - 1]);
    };

    render() {
        let { classes } = this.props;

        let menuItems = this.state.units.map((unit, index) => {
            return (
                <MenuItem className={classes.squareBtn} onClick={() => this.onUnitItemClick(unit)} value={unit.name}>
                    {unit.namePretty}
                </MenuItem>
            );
        });

        let areaMenuItems = this.state.areaUnits.map((unit, index) => {
            return (
                <MenuItem
                    className={classes.squareBtn}
                    onClick={() => this.onAreaUnitItemClick(unit)}
                    value={unit.name}
                >
                    {unit.namePretty}
                </MenuItem>
            );
        });

        let distanceText = this.getLengthUnitFormatted(this.state.distance);
        let perimeterText = this.getLengthUnitFormatted(this.state.perimeter);
        let areaText = this.getAreaUnitFormatted(this.state.area);

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <Typography variant="h6" className={classes.headerText}>
                        Measure
                    </Typography>
                    {this.state.drawing ? (
                        <Button
                            variant="outlined"
                            size="small"
                            disabled={pointLayer.features.length < 2}
                            onClick={this.onFinishMeasureButtonClicked}
                        >
                            Finish
                        </Button>
                    ) : (
                        <Button variant="outlined" size="small" onClick={this.onStartNewMeasure}>
                            Start new
                        </Button>
                    )}
                </div>

                <div className={classes.content}>
                    <Typography variant="body1" gutterBottom className={classes.headerText}>
                        {this.state.isPolygon ? "Perimeter" : "Distance"}
                    </Typography>
                    <div>
                        {this.state.isPolygon ? (
                            <div className={classes.infoRow}>
                                <span className={classes.measurementValue}>{perimeterText.value}</span>
                                <Button
                                    variant="outlined"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className={classes.measurementUnit}
                                    onClick={this.onOpenUnitMenu}
                                >
                                    {perimeterText.unit}
                                    <ArrowDownIcon className={classes.arrowIcon} />
                                </Button>
                                <span className={classes.infoRowSpan}></span>
                                <Tooltip key="copyTT" title="Copy" placement="left">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.copyBtn}
                                        onClick={() => this.onCopyValue(`${perimeterText.value} ${perimeterText.unit}`)}
                                    >
                                        <FilterNone fontSize="small" />
                                    </Button>
                                </Tooltip>
                            </div>
                        ) : (
                            <div className={classes.infoRow}>
                                <span className={classes.measurementValue}>{distanceText.value}</span>
                                <Button
                                    variant="outlined"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className={classes.measurementUnit}
                                    onClick={this.onOpenUnitMenu}
                                >
                                    {distanceText.unit}
                                    <ArrowDownIcon className={classes.arrowIcon} />
                                </Button>
                                <span className={classes.infoRowSpan}></span>
                                <Tooltip key="copyTT" title="Copy" placement="left">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.copyBtn}
                                        onClick={() => this.onCopyValue(`${distanceText.value} ${distanceText.unit}`)}
                                    >
                                        <FilterNone fontSize="small" />
                                    </Button>
                                </Tooltip>
                            </div>
                        )}

                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.unitMenuAnchorEl}
                            keepMounted
                            open={Boolean(this.state.unitMenuAnchorEl)}
                            onClose={this.onCloseUnitMenu}
                        >
                            {menuItems}
                        </Menu>
                    </div>
                    {this.state.isPolygon && (
                        <div>
                            <Typography variant="body1" gutterBottom className={classes.headerText}>
                                Area
                            </Typography>
                            <div className={classes.infoRow}>
                                <span className={classes.measurementValue}>{areaText.value}</span>
                                <Button
                                    variant="outlined"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className={classes.measurementUnit}
                                    onClick={this.onOpenAreaUnitMenu}
                                >
                                    {areaText.unit}
                                    <ArrowDownIcon className={classes.arrowIcon} />
                                </Button>
                                <span className={classes.infoRowSpan}></span>
                                <Tooltip key="copyTT" title="Copy" placement="left">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.copyBtn}
                                        onClick={() => this.onCopyValue(`${areaText.value} ${areaText.unit}`)}
                                    >
                                        <FilterNone fontSize="small" />
                                    </Button>
                                </Tooltip>
                            </div>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.areaUnitMenuAnchorEl}
                                keepMounted
                                open={Boolean(this.state.areaUnitMenuAnchorEl)}
                                onClose={this.onCloseAreaUnitMenu}
                            >
                                {areaMenuItems}
                            </Menu>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        measure: ownProps,
        menu: state.menu,
        mapState: state.map
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        toggleMeasuring: (measure) => dispatch(mapActions.toggleMeasuring(measure)),
        mapChangeClick: (func) => dispatch(mapActions.mapChangeClick(func)),
        setmeasureDist: (distance) => dispatch(mapActions.setmeasureDist(distance))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Measure));
