import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const styles = (theme) => ({
    root: {
        position: "fixed"
    }
});

const Disclaimer = (props) => {
    let { classes } = props;

    function onLinkClick() {
        window.open("https://esox.lautec.com/disclaimer/", "_blank");
    }

    return (
        <div className="ctrl-disclaimer">
            <a onClick={onLinkClick}>Disclaimer</a>
        </div>
    );
};

export default withStyles(styles)(Disclaimer);
