import React from "react";

import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

function Context(props) {
    return (
        <Popper open={props.open} anchorEl={props.anchorEl} placement="bottom-end" transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: "left" }}>
                    <Paper>
                        <ClickAwayListener
                            onClickAway={e => {
                                props.closeCMenu(e);
                            }}
                        >
                            <MenuList autoFocusItem={props.open} id="menu-list-grow">
                                {props.zoomLayer && <MenuItem
                                    onClick={() => {
                                        props.zoomLayer(props.map, props.layer);
                                        props.toggleCMenu();
                                    }}
                                >
                                    Zoom To Layer
                                </MenuItem>}
                                {props.onToggle && <MenuItem
                                    onClick={() => {
                                        props.onToggle(props.map, props.layer);
                                        props.toggleCMenu();
                                    }}
                                >
                                    Toggle Layer
                                </MenuItem>}
                                {props.styleLayer && <MenuItem
                                    onClick={() => {
                                        props.styleLayer(props.map, props.layer);
                                        props.toggleCMenu();
                                    }}
                                >
                                    Change Layer Style
                                </MenuItem>}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}

export default Context;
