import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import isMobile from "ismobilejs";

import * as mapActions from "../../actions/map";

import OpenIcon from "@material-ui/icons/Layers";
import CloseIcon from "@material-ui/icons/Clear";
import Popover from "@material-ui/core/Popover";
import { SwatchesPicker } from "react-color";
import { Typography } from "@material-ui/core";
import appStyles from "../../utils/styles";

const styles = (theme) => ({
    root: {
        position: "absolute",
        backgroundColor: "white",
        bottom: 32,
        left: 6,
        zIndex: 1
    },
    rootClosed: {
        position: "absolute",
        backgroundColor: "transparent",
        borderRadius: 5,
        bottom: 32,
        left: 6,
        zIndex: 1,
        minWidth: 20,
        opacity: 0.9
    },
    labels: {
        padding: 8,
        maxHeight: "45vh",
        overflow: "auto"
    },
    label: {
        fontFamily: ["Roboto", '"Helvetica Neue"', "sans-serif"],
        display: "flex",
        alignItems: "center",
        padding: 8,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#cacaca"
        }
    },
    selectedLabel: {
        display: "flex",
        alignItems: "center",
        padding: 8,
        backgroundColor: "lightgray"
    },
    multiLabel: {
        marginLeft: 8
    },
    multiLabelHeaderText: {
        paddingTop: 6,
        paddingBottom: 4,
        fontSize: 14
    },
    labelText: {
        flexGrow: 1,
        marginLeft: 8,
        fontSize: 14
    },
    multiLabelText: {
        flexGrow: 1,
        marginLeft: 8,
        fontSize: 14
    },
    colorBox: {
        height: 12,
        width: 12
    },
    closeButton: {
        padding: 4,
        paddingLeft: 16,
        backgroundColor: appStyles.color.HEADER_BACKGROUND,
        color: "white",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between"
    },
    openButton: {
        padding: 8,
        backgroundColor: appStyles.color.HEADER_BACKGROUND,
        color: "white",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "50%"
    }
});

class Legend extends Component {
    state = {
        labels: [],
        open: true,
        selectedLabel: { backgroundColor: "black" },
        anchorEl: null,
        colorPickerOpen: false
    };

    componentDidUpdate(prevProps) {
        if (prevProps.styleState.styles !== this.props.styleState.styles && this.props.styleState.styles.length) {
            this.convertStylesToLegend(this.props.styleState.styles);
        }
    }

    convertStylesToLegend(styles) {
        let theStyles = styles.reduce((a, b) => a.concat(b));

        let labels = [];

        theStyles.map((styleObj) => {
            let styleLabels = styleObj.style.map((style) => {
                switch (style.type) {
                    case "fill":
                        return this.styleTolabel(style, styleObj.id, "fill-color", "fill-outline-color");
                    case "fill-extrusion":
                        return this.styleTolabel(style, styleObj.id, "fill-extrusion-color", "fill-extrusion-color");
                    case "line":
                        return this.styleTolabel(style, styleObj.id, "line-color", "line-outline-color");
                    case "circle":
                        return this.styleTolabel(style, styleObj.id, "circle-color", "circle-stroke-color");
                    case "symbol":
                        return this.styleTolabel(style, styleObj.id, "text-color", "icon-halo-color");
                    default:
                        return null;
                }
            });

            labels.push(...styleLabels);
        });

        this.setState({
            labels: labels
        });
    }

    styleTolabel(style, styleId, fillProp, fillOutlineProp) {
        let fillColor = style.paint.find((paintType) => paintType.name === fillProp);
        let outlineColor = style.paint.find((paintType) => paintType.name === fillOutlineProp);

        let layerId = styleId + ":" + style.sourceName + ":0";

        if (Array.isArray(fillColor.value)) {
            return this.handleColorArray(layerId, style, fillColor.value);
        }

        return {
            text: style.name,
            layerId: layerId,
            backgroundColor: fillColor ? fillColor.value : "transparent",
            outlineColor: outlineColor ? outlineColor.value : "transparent",
            type: style.type,
            multi: false
        };
    }

    handleColorArray(layerId, style, colorValues) {
        //Remove filterType and get function from array

        switch (colorValues[0]) {
            case "match":
                return this.handleMatchExpression(layerId, style, colorValues);
            case "interpolate":
                return this.handleInterpolateExpression(layerId, style, colorValues);
        }
    }

    handleMatchExpression(layerId, style, colorValues) {
        let values = colorValues.slice(2, colorValues.length);

        let pairs = [];
        //Build pairs from remaning values, that are in this format ["key","value","key","value"]
        for (let i = 0; i < values.length - 1; i += 2) {
            pairs.push({
                name: values[i],
                value: values[i + 1]
            });
        }

        return {
            text: style.name,
            layerId: layerId,
            backgroundColor: pairs,
            outlineColor: "transparent",
            type: style.type,
            multi: true
        };
    }

    handleInterpolateExpression(layerId, style, colorValues) {
        let values = colorValues.slice(3, colorValues.length);

        let pairs = [];
        //Build pairs from remaning values, that are in this format ["key","value","key","value"]
        for (let i = 0; i < values.length - 1; i += 2) {
            pairs.push({
                name: values[i],
                value: values[i + 1]
            });
        }

        return {
            text: style.name,
            layerId: layerId,
            backgroundColor: pairs,
            outlineColor: "transparent",
            type: style.type,
            multi: true
        };
    }

    onOpenToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    onLabelClick = (e, label) => {
        this.setState({
            anchorEl: e.target,
            colorPickerOpen: true,
            selectedLabel: label
        });
    };

    onColorPicked = (color) => {
        let label = this.state.selectedLabel;

        this.props.updateMapPaint({
            layerId: label.layerId,
            properties: [
                {
                    name: (label.type === "symbol" ? "text" : label.type) + "-color",
                    value: color.hex
                }
            ]
        });

        label.backgroundColor = color.hex;

        let updatedLabels = this.state.labels.map((item, index) => {
            if (label.layerId === item.layerId) {
                return label;
            }
            return item;
        });
        this.setState({
            labels: updatedLabels
        });
    };

    onColorPickerClose = () => {
        this.setState({
            colorPickerOpen: false,
            selectedLabel: { backgroundColor: "black" }
        });
    };

    render() {
        let { classes } = this.props;

        let labels = this.renderLabels(classes);

        //Only show legend if there are any labels
        if (labels.length === 0) {
            return null;
        }

        return (
            <div className={this.state.open ? classes.root : classes.rootClosed}>
                <div
                    size="small"
                    onClick={this.onOpenToggle}
                    className={this.state.open ? classes.closeButton : classes.openButton}
                >
                    {this.state.open && <Typography>Legend</Typography>}
                    {this.state.open ? <CloseIcon></CloseIcon> : <OpenIcon></OpenIcon>}
                </div>
                <div className={classes.labels} style={{ display: this.state.open ? "block" : "none" }}>
                    {labels}
                </div>
                <Popover
                    open={this.state.colorPickerOpen}
                    anchorEl={this.state.anchorEl}
                    onClose={this.onColorPickerClose}
                    PaperProps={{
                        style: { marginLeft: 8, overflowY: "hidden" }
                    }}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "left"
                    }}
                >
                    <SwatchesPicker
                        color={this.state.selectedLabel.backgroundColor}
                        onChangeComplete={this.onColorPicked}
                    />
                </Popover>
            </div>
        );
    }

    renderLabels(classes) {
        let visableLabels = this.state.labels.filter(
            (layerLabel) => this.props.legendState.visableLayers[layerLabel.layerId] && layerLabel.type !== "symbol"
        );
        return visableLabels.map((label, labelIndex) => {
            let style;
            switch (label.type) {
                case "fill":
                    style = this.styleFillLabel(label);
                    break;
                case "fill-extrusion":
                    style = this.styleFillExtrusionLabel(label);
                    break;
                case "circle":
                    style = this.styleCircleLabel(label);
                    break;
                case "line":
                    style = this.styleLineLabel(label);
                    break;
                case "symbol":
                    style = this.styleSymbolLabel(label);
                    break;
                default:
                    style = null;
            }

            if (label.multi) {
                return this.renderMultiLabel(label, labelIndex, style, classes);
            } else {
                return this.renderSingleLabel(label, labelIndex, style, classes);
            }
        });
    }

    renderMultiLabel(label, labelIndex, style, classes) {
        let pairs = label.backgroundColor.map((subLabel, subLabelIndex) => {
            let subStyle = { ...style };
            subStyle.backgroundColor = subLabel.value;

            return (
                <div key={subLabelIndex} className={classes.label}>
                    <div style={subStyle} className={classes.colorBox} />
                    <div className={classes.multiLabelText}>{subLabel.name}</div>
                </div>
            );
        });

        return (
            <div key={labelIndex} className={classes.multiLabel}>
                <div className={classes.multiLabelHeaderText}>{label.text}</div>
                {pairs}
            </div>
        );
    }

    renderSingleLabel(label, labelIndex, style, classes) {
        return (
            <div
                key={labelIndex}
                onClick={(e) => this.onLabelClick(e, label)}
                className={label.layerId === this.state.selectedLabel.layerId ? classes.selectedLabel : classes.label}
            >
                <div style={style} className={classes.colorBox}>
                    {label.type === "symbol" ? "A" : ""}
                </div>
                <div className={classes.labelText}>{label.text}</div>
            </div>
        );
    }

    styleFillLabel(label) {
        return {
            backgroundColor: label.backgroundColor,
            border: `${label.outlineColor} 1px solid`
        };
    }

    styleFillExtrusionLabel(label) {
        return {
            backgroundColor: label.backgroundColor,
            border: `${label.outlineColor} 1px solid`
        };
    }

    styleCircleLabel(label) {
        return {
            backgroundColor: label.backgroundColor,
            border: `${label.outlineColor} 1px solid`,
            borderRadius: 16
        };
    }

    styleLineLabel(label) {
        return {
            backgroundColor: label.backgroundColor,
            border: `${label.outlineColor} 1px solid`,
            height: 1
        };
    }

    styleSymbolLabel(label) {
        return {
            backgroundColor: "transparent",
            color: label.backgroundColor,
            lineHeight: "16px",
            fontWeight: "bold",
            fontSize: "16px",
            width: "14px"
        };
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        styleState: state.style,
        legendState: state.legend
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateMapPaint: (paint) => dispatch(mapActions.updatePaint(paint))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Legend));
