import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { history } from "../store";
import * as authAction from "../actions/auth";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import LockIcon from "@material-ui/icons/Lock";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import { Checkbox } from "@material-ui/core";

const styles = (theme) => ({
    outer: {
        display: "flex",
        alignItems: "center",
        overflow: "auto",
        width: "100%",
        height: "100%",
        background: "#f5f7f8",
        
    },
    root: {
        background: "white",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 360
    },
    logo: {
        verticalAlign: "top",
        width: '100%'
    },
    error: {
        fontSize: "10px",
        color: "red",
        textAlign: "left"
    },
    loginForm: {
        display: "flex",
        flexDirection: "column"
    },
    loginBtn: {
        backgroundColor: "#0075bb",
        color: "#ffffff",
        position: "relative",
        width: "100%",
        height: 52
    },
    formInput: {
        border: "1px solid #c2c8d0",
        fontSize: 16,
        marginBottom: 16,
        height: 52,
        outline: "none",
        padding: 16,
        width: "100%"
    },
    header: {
        padding: "40px 40px 24px",
        lineHeight: 1.5,
        textAlign: "center",
        flexShrink: 0
    },
    title: {
        marginTop: 24,
        fontSize: 24,
        fontalign: "center"
    },
    container: {
        boxShadow: "0 12px 40px rgba(0,0,0,.12)",
        padding: 16
    },
    forgotPwdLink: {
        marginTop: 0,
        textAlign: "left"
    },
    forgotAndRemberContainer: { display: "flex", alignItems: "baseline" },
    forgotPwd: {
        padding: 2,
        fontSize: 14,
        color: "#0a84ae",
        textDecoration: "none",
        lineHeight: 1.5
    },
    remberMeContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: -8,
        padding: 2,
        flexGrow: 1
    }
});

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            remberMe: false,
            error: null
        };
    }

    login() {
        this.props.login(this.state.username, this.state.password, this.state.remberMe).then((res) => {
            if (res.request.status === 200) {
                history.push("/map/search");
            } else {
                this.setState({ error: "Invalid username or password" });
            }
        });
    }

    usernameChange(e) {
        this.setState({
            username: e.target.value
        });
    }

    passwordChange(e) {
        this.setState({
            password: e.target.value
        });
    }

    _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            if (!this.state.username || !this.state.password) {
                this.setState({ error: "Please enter username and password" });
                return;
            }
            this.login();
        } else {
            this.setState({ error: null });
        }
    };

    onChangeRemberMe = () => {
        this.setState({
            remberMe: !this.state.remberMe
        });
    };

    render() {
        let { classes } = this.props;

        return (
            <div className={classes.outer}>
                <div className={classes.root + " " + classes.container}>
                    <header className={classes.header}>
                        <img
                            className={classes.logo}
                            src="https://lautecprod.blob.core.windows.net/externals/lautec-logo.png"
                            alt="Welcome"
                        ></img>
                        <h1 className={classes.title}>Welcome</h1>
                        <p class="text-simple">Log in to LAUTEC WINDGIS</p>
                    </header>
                    <div>
                        <div className="login-container">
                            <Input
                                className={classes.formInput}
                                onKeyDown={this._handleKeyDown}
                                id="input-with-icon-adornment"
                                placeholder="Username"
                                value={this.state.username}
                                onChange={(e) => this.usernameChange(e)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                }
                            />
                            <Input
                                className={classes.formInput}
                                onKeyDown={this._handleKeyDown}
                                id="full-width"
                                label="Password"
                                type="password"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>
                                }
                                placeholder="Password"
                                value={this.state.password}
                                onChange={(e) => this.passwordChange(e)}
                            />
                            {this.state.error && (
                                <Typography variant="body2" className={classes.error}>
                                    {this.state.error}
                                </Typography>
                            )}
                            <div className={classes.forgotAndRemberContainer}>
                                <div className={classes.remberMeContainer}>
                                    <Typography variant="body1" className="name">
                                        {" "}
                                        Rember Me
                                    </Typography>
                                    <Checkbox value={this.state.remberMe} onChange={this.onChangeRemberMe}></Checkbox>
                                </div>

                                <div className={classes.forgotPwdLink}>
                                    <a class={classes.forgotPwd} href="/">
                                        Forgot Password?
                                    </a>
                                </div>
                            </div>
                        </div>

                        <Button
                            className={classes.loginBtn}
                            variant="outlined"
                            color="primary"
                            onClick={() => this.login()}
                        >
                            Login
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        // mapState: state.map
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        login: (username, password, remberMe) => dispatch(authAction.authenticate(username, password, remberMe))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
