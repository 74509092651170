import * as types from "../actions/actionTypes";

const infoBoxInit = {
    features: []
};

export function infoBoxReducer(state = infoBoxInit, action) {
    switch (action.type) {
        case types.SELECT_FEATURES:
            return {
                ...state,
                features: action.result
            };
        default:
            return state;
    }
}
