import React, { Component } from "react";

import MapComponent from "../components/map/map";
import ToolsMenu from "../components/sidebar/toolsMenu/toolsMenu";
import SidebarRoot from "../components/sidebar/sidebarRoot";

// export default
class MapView extends Component {
    componentDidMount() {}

    render() {
        let { params } = this.props.match;

        return (
            <div className="container main-view">
                <MapComponent
                    mapName={this.props.match.params.mapName}
                    startLocation={{ lat: params.lat, lng: params.long }}
                    zoom={params.zoom}
                ></MapComponent>
                <ToolsMenu
                    mapName={this.props.match.params.mapName}
                    enabledLayers={this.props.match.params.enabledLayers}
                    history={this.props.history}
                    match={this.props.match}
                />
                <SidebarRoot match={this.props.match} params={params} />
            </div>
        );
    }
}

export default MapView;
