import * as types from "../actions/actionTypes";
import apiClient from "../actions/apiClient";
import moment from "moment";

const authInit = {
    authenticated: false,
    fetching: true,
    token: null,
    expiresOn: new Date(),
    authPromise: null,
    failReason: ""
};

export function authReducer(state = authInit, action) {
    switch (action.type) {
        case types.AUTHENTICATED:
            apiClient.defaults.headers.common["Authorization"] = "Bearer " + action.result.token;
            return {
                token: action.result.token,
                fetching: false,
                authenticated: true,
                expiresOn: moment(action.result.expiresOn).toDate()
            };
        case types.AUTHENTICATING:
            return { ...state, fetching: true, authPromise: action.authPromise };
        case types.AUTHENTICATING_FAILED:
            return { ...state, fetching: false, failReason: action.result, authPromise: null };
        default:
            return state;
    }
}
