import React, { useState, Fragment } from "react";
import IconButton from "@material-ui/core/IconButton";
import AccountBox from "@material-ui/icons/AccountBox";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { history } from "../../../store";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import * as authAction from "../../../actions/auth";
import { BrowserRouter } from "react-router-dom";

const UserMenu = (props) => {
    const [contextOpen, setContextOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const toggleCMenu = (e) => {
        if (e) e.stopPropagation();
        setContextOpen((contextOpen) => !contextOpen);
        return null;
    };

    const signOut = (e) => {
        props.logout();
        history.replace("/");
    };

    let { classes, loginState } = props;
    return (
        <Fragment>
            <div className={"endAlign " + classes.padding12}>
                <IconButton
                    className={classes.closeButton}
                    ref={anchorRef}
                    aria-controls={contextOpen ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    aria-label="Account"
                    onClick={toggleCMenu}
                >
                    <AccountBox fontSize="small" />
                </IconButton>
            </div>
            <Popper open={contextOpen} placement="right-end" anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: "left" }}>
                        <Paper>
                            <MenuList autoFocusItem={contextOpen} id="menu-list-grow">
                                {loginState.username && (
                                    <Fragment>
                                        <MenuItem style={{ backgroundColor: "transparent", cursor: "initial" }}>
                                            <span>{loginState.username.toUpperCase()}</span>
                                        </MenuItem>
                                        <Divider />
                                    </Fragment>
                                )}
                                <MenuItem onClick={signOut}>
                                    <span>Sign out</span>
                                </MenuItem>
                            </MenuList>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Fragment>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        loginState: state.login
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logout: () => dispatch(authAction.logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
