import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { MuiConfig } from "../../MuiConfig";
import Measure from "../sidebar/measure/measure";

import GlMap from "mapbox-gl";

import * as mapActions from "../../actions/map";
import * as MapboxDraw from "@mapbox/mapbox-gl-draw";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";

import ToggleBaseLayer from "./toggleBaselayer";
import Config from "../../config";
import Legend from "./legend";
import MobileInfoBox from "./mobileEraInfoBox";
import InfoBox from "./infoBox";
import EraInfoBox from "./eraInfoBox";
import isMobile from "../../utils/isMobile";
import ResizeObserver from "resize-observer-polyfill";

import PolygonLabelGenerator from "../../utils/polygonLabelGenerator";
import SearchLayer from "./searchLayer";
import PitchToggle from "./pitchToggle";
import Disclaimer from "./disclaimer";
import CopyLocation from "./copyLocation";

let marker, getMap, getDraw, popupEl;

var modes = MapboxDraw.modes;
modes.static = StaticMode;

const theme = createMuiTheme(MuiConfig);

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapLoaded: false,
            selectedFeatures: [],
            enabledWidgets: {},
            isAerial: true
        };
    }

    componentDidMount() {
        this.initMap();
        let map = document.getElementById("map");
        const ro = new ResizeObserver((entries) => {
            setTimeout(() => {
                this._resize();
            }, 25);
        });
        ro.observe(map);
        getMap = this.getMap;
        getDraw = this.getDraw;
    }

    getMap = () => {
        return this.map;
    };

    getDraw = () => {
        return this.draw;
    };

    initMap() {
        GlMap.accessToken = Config.mapboxToken;
        this.map = new GlMap.Map({
            container: "map",
            attributionControl: false,
            style: Config.baseLayers.street,
            zoom: Config.mapZoom,
            center: Config.mapCenter,
            preserveDrawingBuffer: true,
            hash: false,
            minZoom: 1,
            maxZoom: 22,
            transformRequest: (url, resourceType) => {
                if (resourceType === "Tile" && url.startsWith(Config.apiUrl)) {
                    return {
                        url: url + "?key=" + this.props.auth.token
                        // headers: { 'Authorization': 'Bearer ' + this.props.auth.token }
                    };
                }
            }
        });

        if (this.props.startLocation.lat) {
            this.map.setCenter(this.props.startLocation);
            this.map.setZoom(8);
        }

        if (this.props.zoom) {
            this.map.setZoom(this.props.zoom);
        }

        const nav = new GlMap.NavigationControl();
        this.map.addControl(nav, "top-right");

        this.map.addControl(new PitchToggle({ minpitchzoom: 11 }), "top-right");

        const fs = new GlMap.FullscreenControl({});
        this.map.addControl(fs, "top-right");

        this.draw = new MapboxDraw({
            controls: {
                point: false,
                line_string: false,
                polygon: false,
                combine_features: false,
                uncombine_features: false,
                trash: false
            },
            modes: modes
        });
        //this.map.addControl(this.draw, "top-left");

        // const disclaimer = new Disclaimer();
        // this.map.addControl(disclaimer, "bottom-right");

        const scale = new GlMap.ScaleControl({});
        this.map.addControl(scale, "bottom-right");

        this.polygonLabelGenerator = new PolygonLabelGenerator(this.map);

        this.map.on("zoomend", () => this._mapZoom());

        this.map.on("load", () => this._mapLoad());

        this.map.on("style.load", () => {
            this.map.addSource("backgroundd", {
                type: "raster",
                tiles: [Config.baseLayers.street],
                tileSize: 256
            });
            this.map.off("style.load");
        });
    }

    componentWillUnmount() {
        this.polygonLabelGenerator.removeLayers();
        this.props.clearMap();
        this.map.remove();
    }

    _mapLoad = () => {
        this.map.on("click", (e) =>
            this.props.mapState.onClick ? this.props.mapState.onClick(e) : this._onMapClick(e)
        );
        // this.map.on("touchstart",
        //     (e) =>
        //         this.props.mapState.onClick ? this.props.mapState.onClick(e) : this._onMapClick(e)
        // );
        this.map.on("moveend", (e) => this._onMapMoveEnd(e));
        this.addLayers(this.props.mapState.layers);
        this.changeLayout(this.props.mapState.layouts);
        this.changePaint(this.props.mapState.paints);
        this.setState({
            mapLoaded: true
        });

        if (this.props.startLocation.lat) {
            setTimeout(() => {
                this.MapClick({ latlng: this.props.startLocation });
            }, 1000);
        }

        this.props.mapLoaded();
    };

    _mapZoom = () => {
        let zoom = this.map.getZoom();
        this.props.zoomEnd(zoom);
    };

    _resize = () => {
        this.map.resize();
    };

    _onMapMoveEnd = () => {
        this.polygonLabelGenerator.generateLabels();
    };

    _onLayerClick = (e) => {
        // let feature = e.features[0];
        // let gids = e.features.map(x => x.properties.ogc_fid);
        // let features = this.map.querySourceFeatures(feature.source, {
        //     sourceLayer: feature.sourceLayer
        //     // filter: ['in', 'gid', ...gids]
        // });
        // if (this.map.getSource("highlight-features")) {
        //     this.map.removeLayer("highlight");
        //     this.map.removeSource("highlight-features");
        // }
        // this.map.addSource("highlight-features", {
        //     type: "geojson",
        //     data: {
        //         type: "FeatureCollection",
        //         features: features
        //     }
        // });
        // if (feature.layer.type === "symbol") {
        //     let layerId = feature.layer.id.split("_")[0];
        //     feature.layer.type = this.props.mapState.layers.find(x => x.layerId === layerId).type;
        // }
        // //don't highlight anything for symbol
        // if (feature.layer.type !== "symbol") {
        //     let highlightLayer = this._GetHighlightType(feature.layer.type);
        //     this.map.addLayer(highlightLayer, this.props.mapState.layers[0].layerId);
        // }
    };

    _onToolTipClose = () => {
        if (this.map.getSource("highlight-features")) {
            if (this.map.getLayer("highlight")) {
                this.map.removeLayer("highlight");
            }
            this.map.removeSource("highlight-features");
        }
    };

    _GetHighlightType(geomType) {
        switch (geomType) {
            case "fill":
                return {
                    id: "highlight",
                    type: "fill",
                    source: "highlight-features",
                    layout: {},
                    paint: {
                        "fill-color": "gray"
                    }
                };
            case "fill-extrusion":
                return {
                    id: "highlight",
                    type: "fill",
                    source: "highlight-features",
                    layout: {},
                    paint: {
                        "fill-color": "gray"
                    }
                };
            case "line":
                return {
                    id: "highlight",
                    type: "line",
                    source: "highlight-features",
                    layout: {},
                    paint: {
                        "line-color": "gray",
                        "line-width": 4
                    }
                };
            case "circle":
                return {
                    id: "highlight",
                    type: "circle",
                    source: "highlight-features",
                    layout: {},
                    paint: {
                        "circle-color": "gray",
                        "circle-radius": 6
                    }
                };
            case "symbol":
                return {
                    id: "highlight",
                    type: "symbol"
                };
            default:
                return null;
        }
    }

    _onMapClick = (e) => {
        let layers = this.props.mapState.layers.map((x) => x.layerId);

        this.props.mapClick(e.lngLat);
        let features = this.map.queryRenderedFeatures(e.point, { layers });
        //no popups for labels
        features = features.filter((e) => {
            return e.layer.type !== "symbol" && e.sourceLayer === "Era5 Final";
        });
        if (features.length === 0) {
            if (this.map.getSource("highlight-features")) {
                if (this.map.getLayer("highlight")) {
                    this.map.removeLayer("highlight");
                }
                this.map.removeSource("highlight-features");
            }
            return;
        }

        //Use mobile infobox instead
        if (isMobile.phone) {
            this.props.selectFeatures(features);
            return;
        }

        const placeholder = document.createElement("div");

        let eraData = features[0].properties.hasOwnProperty("long");

        ReactDOM.render(
            <MuiThemeProvider theme={theme}>
                {eraData ? (
                    <EraInfoBox features={features} layers={this.props.mapState.layers} />
                ) : (
                    <InfoBox features={features} layers={this.props.mapState.layers} />
                )}
            </MuiThemeProvider>,
            placeholder
        );

        popupEl = new GlMap.Popup({ closeButton: true, maxWidth: "400px", padding: 0 })
            .setLngLat(e.lngLat)
            .setDOMContent(placeholder)
            .addTo(this.map);
        popupEl.on("close", this._onToolTipClose);
    };

    MapClick = (e) => {
        let layers = this.props.mapState.layers.map((x) => x.layerId);

        let features = this.map.queryRenderedFeatures(null, {
            layers,
            filter: ["all", ["==", ["get", "lat"], e.latlng.lat], ["==", ["get", "long"], e.latlng.lng]]
        });
        //no popups for labels
        features = features.filter((e) => {
            return e.layer.type !== "symbol";
        });
        if (features.length === 0) {
            if (this.map.getSource("highlight-features")) {
                if (this.map.getLayer("highlight")) {
                    this.map.removeLayer("highlight");
                }
                this.map.removeSource("highlight-features");
            }
            return;
        }

        //Use mobile infobox instead
        if (isMobile.phone) {
            this.props.selectFeatures(features);
            return;
        }

        const placeholder = document.createElement("div");

        let eraData = features[0].properties.hasOwnProperty("long");

        ReactDOM.render(
            <MuiThemeProvider theme={theme}>
                {eraData ? (
                    <EraInfoBox features={features} layers={this.props.mapState.layers} />
                ) : (
                    <InfoBox features={features} layers={this.props.mapState.layers} />
                )}
            </MuiThemeProvider>,
            placeholder
        );

        popupEl = new GlMap.Popup({ closeButton: true, maxWidth: "400px", padding: 0 })
            .setLngLat(e.latlng)
            .setDOMContent(placeholder)
            .addTo(this.map);
        popupEl.on("close", this._onToolTipClose);
    };

    removeLayers(previousLayers, currentLayers) {
        let LayersMap = currentLayers.reduce((a, b, index) => {
            a[b.layerId] = index;
            return a;
        }, {});

        for (let i = 0; i < previousLayers.length; i++) {
            let layer = previousLayers[i];

            if (!LayersMap[layer.layerId]) {
                this.map.removeLayer(layer.layerId);
            }
        }
    }

    addLayers(layers) {
        let mapLayers = this.map.getStyle().layers;
        // Find the index of the first symbol layer in the map style
        let firstSymbolId;
        for (let i = 0; i < mapLayers.length; i++) {
            if (mapLayers[i].type === "symbol") {
                firstSymbolId = mapLayers[i].id;
                break;
            }
        }

        for (let i = 0; i < layers.length; i++) {
            let layer = layers[i];

            if (!this.map.getSource(layer.sourceId)) {
                this.map.addSource(layer.sourceId, {
                    type: "vector",
                    tiles: [`${Config.apiUrl}tile/public/${layer.sourceId}/{z}/{x}/{y}`],
                    minzoom: layer.sourceMinZoom,
                    maxzoom: layer.sourceMaxZoom
                });
            }

            const vectorLayer = {
                id: layer.layerId,
                type: layer.type,
                source: layer.sourceId,
                "source-layer": layer.sourceName,
                minzoom: layer.minZoom,
                maxzoom: layer.maxZoom,
                layout: {}
            };

            this.map.addLayer(vectorLayer, firstSymbolId);
            //first layer used to know where to add satellite map

            firstSymbolId = vectorLayer.id;
            this.map.firstLayer = vectorLayer.id;
            this.map.on("click", layer.layerId, this._onLayerClick);
        }

        for (let i = 0; i < layers.length; i++) {
            let layer = layers[i];

            if (layer.type === "symbol") {
                this.map.moveLayer(layer.layerId);
            }
        }
    }

    changeLayers(previousLayers, currentLayers) {
        //Handle initial condition
        if (previousLayers.length === 0) {
            previousLayers = currentLayers;
        }

        //Map layerId to index for previous layers
        let previousLayersMap = previousLayers.reduce((a, b, index) => {
            a[b.layerId] = index;
            return a;
        }, {});

        for (let i = 0; i < currentLayers.length; i++) {
            let layer = currentLayers[i];
            //Find currentLayer in previous layers
            let previousLayer = previousLayers[previousLayersMap[layer.layerId]];

            if (layer.type !== previousLayer.type) {
                this.map.removeLayer(layer.layerId);

                const vectorSource = {
                    id: layer.layerId,
                    type: layer.type,
                    source: layer.sourceId,
                    "source-layer": layer.sourceName,
                    paint: {}
                };
                this.map.addLayer(vectorSource);
            }
        }
    }

    changePaint(paints) {
        for (let k = 0; k < paints.length; k++) {
            let paint = paints[k];

            for (let i = 0; i < paint.properties.length; i++) {
                let paintProperty = paint.properties[i];

                this.map.setPaintProperty(paint.layerId, paintProperty.name, paintProperty.value);
            }
        }
    }

    changeLayout(layouts) {
        //remove highlight if a layer's layout is changed
        if (this.map.getSource("highlight-features")) {
            if (this.map.getLayer("highlight")) {
                this.map.removeLayer("highlight");
            }
            this.map.removeSource("highlight-features");
        }

        for (let k = 0; k < layouts.length; k++) {
            let layout = layouts[k];
            for (let i = 0; i < layout.properties.length; i++) {
                let layoutProperty = layout.properties[i];
                this.map.setLayoutProperty(layout.layerId, layoutProperty.name, layoutProperty.value);
            }
        }
    }

    toggleBaseLayerHandler = () => {
        this.setState({ isAerial: !this.state.isAerial });
        this.changeBaseLayer(this.state.isAerial);
    };

    changeBaseLayer = (isAerial) => {
        // Remove current baselayer
        if (this.map.getLayer("background-layer")) {
            this.map.removeLayer("background-layer");
        }
        if (this.map.getSource("backgroundd")) {
            this.map.removeSource("backgroundd");
        }

        // Add baselayer dependent on current baselayer state
        if (isAerial) {
            this.map.addSource("backgroundd", {
                type: "raster",
                tiles: [Config.baseLayers.aerial],
                tileSize: 256,
                attribution:
                    'Map tiles by <a target="_top" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>'
            });

            this.map.addLayer(
                {
                    id: "background-layer",
                    type: "raster",
                    source: "backgroundd",
                    paint: {}
                },
                // Placing base layer below first map layer
                this.map.firstLayer
            );
        }
    };

    flyTo(location) {
        this.map.flyTo({
            center: [location.y, location.x],
            zoom: 7,
            speed: 0.5,
            curve: 1
        });

        if (marker) marker.remove();

        marker = new GlMap.Marker().setLngLat({ lng: location.y, lat: location.x }).addTo(this.map);
    }

    clearMap(mapName) {
        let layers = this.map
            .getStyle()
            .layers.filter((x) => x.hasOwnProperty("source") && x.source.startsWith(mapName));

        for (let i = 0; i < layers.length; i++) {
            let layer = layers[i];
            this.map.off("click", layer.id, this._onLayerClick);
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.state.mapLoaded) {
            return;
        }
        if (prevProps.mapState.layers.length < this.props.mapState.layers.length) {
            this.addLayers(
                this.props.mapState.layers.slice(prevProps.mapState.layers.length, this.props.mapState.layers.length)
            );
        } else if (prevProps.mapState.layers.length > this.props.mapState.layers.length) {
            this.removeLayers(prevProps.mapState.layers, this.props.mapState.layers);
        } else if (JSON.stringify(prevProps.mapState.layers) !== JSON.stringify(this.props.mapState.layers)) {
            this.changeLayers(prevProps.mapState.layers, this.props.mapState.layers);
        }

        if (JSON.stringify(prevProps.mapState.paints) !== JSON.stringify(this.props.mapState.paints)) {
            this.changePaint(this.props.mapState.paints);
        }

        if (JSON.stringify(prevProps.mapState.layouts) !== JSON.stringify(this.props.mapState.layouts)) {
            this.changeLayout(this.props.mapState.layouts);
        }

        if (JSON.stringify(prevProps.mapState.baseLayer) !== JSON.stringify(this.props.mapState.baseLayer)) {
            this.changeBaseLayer(this.props.mapState.baseLayer);
        }

        if (prevProps.mapState.flyTo !== this.props.mapState.flyTo) {
            this.flyTo(this.props.mapState.flyTo);
        }

        if (prevProps.mapState.fitBounds !== this.props.mapState.fitBounds) {
            this.fitBounds(this.props.mapState.fitBounds.bbox, this.props.mapState.fitBounds.options);
        }

        if (prevProps.widgets !== this.props.widgets) {
            this.setState({
                enabledWidgets: this.props.widgets
                    .filter((x) => x.toggled)
                    .reduce((a, b) => {
                        a[b.name] = true;
                        return a;
                    }, {})
            });
        }
    }

    fitBounds(bbox, options) {
        this.map.fitBounds(bbox, options);
    }

    onCopyLocation = () => {
        let pos = this.map.getCenter();
        let zoom = this.map.getZoom();

        let link = `https://esox.lautec.com/map/?location=${parseFloat(pos.lat)}/${parseFloat(pos.lng)}&zoom=${zoom}`;

        window.parent.postMessage({ type: "copy-to-clipboard", toCopy: link }, "*");
    };

    render() {
        return (
            <div id="map">
                <Legend loaded={this.props.mapState.loaded} />
                {this.state.enabledWidgets["measure"] && <Measure />}
                {Config.layerSearch.enabled && <SearchLayer />}
                <MobileInfoBox />
                <ToggleBaseLayer isaerial={this.state.isAerial} toggle={this.toggleBaseLayerHandler} />
                <CopyLocation onClick={this.onCopyLocation} />
                <Disclaimer />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mapState: state.map,
        auth: state.auth,
        widgets: state.tools.widgets
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        selectFeatures: (features) => dispatch(mapActions.selectFeatures(features)),
        clearMap: () => dispatch(mapActions.clear()),
        // setmeasureDist: distance => dispatch(mapActions.setmeasureDist(distance)),
        mapLoaded: () => dispatch(mapActions.mapLoaded()),
        zoomEnd: (zoom) => dispatch(mapActions.zoomEnd(zoom)),
        mapClick: (lnglat) => dispatch(mapActions.mapClick(lnglat))
    };
};

let MapComponent = connect(mapStateToProps, mapDispatchToProps)(Map);

export { getMap, getDraw };

export default MapComponent;
