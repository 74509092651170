import React from "react";
import { history } from "./store";
import { HashRouter as Router, Route } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { MuiConfig } from "./MuiConfig";

import "mapbox-gl/dist/mapbox-gl.css";
import "./App.css";

import MainView from "./views/mapView";
import LoginView from "./views/loginView";

const theme = createMuiTheme(MuiConfig);

export default function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <div className="App">
                <Router history={history}>
                    <Route path="/" exact component={LoginView} />
                    <Route path="/map" component={MainView} />
                    {/* <Route path="/location/:lat/:long" component={MainView} /> */}
                    <Route path="/location/:lat/:long/:zoom" component={MainView} />
                </Router>
            </div>
        </MuiThemeProvider>
    );
}
