import React from "react";

export function BuildProperties(feature, classes) {
    let properties = Object.keys(feature.properties).reduce((a, b) => {
        a.push({
            key: b,
            value: feature.properties[b]
        });
        return a;
    }, []);

    return properties.map((propKeyValue, propIndex) => {
        let value;
        if (["ogc_fid", "geom_type"].indexOf(propKeyValue.key) >= 0) {
            return false;
        }

        // Creating links
        if (propKeyValue.key.includes("link") || propKeyValue.key.includes("foto")) {
            value = (
                <a href={propKeyValue.value} target="_blank" rel="noopener noreferrer">
                    Link{" "}
                </a>
            );
            // Formatting dates
        } else if (propKeyValue.key.includes("dato")) {
            let date = new Date(propKeyValue.value);
            let dd = date.getDate();
            let mm = date.getMonth() + 1; // january is 0
            let yyyy = date.getFullYear();
            value = `${dd}/${mm}-${yyyy}`;
        } else {
            value = propKeyValue.value;
        }

        return (
            <tr className={classes.property} key={propIndex}>
                <td className={classes.propertyKey}>{propKeyValue.key}</td>
                <td className={classes.propertyValue}>{value}</td>
            </tr>
        );
    });
}
