import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Divider, Input, Typography, IconButton, InputAdornment } from "@material-ui/core";
import * as mapActions from "../../../actions/map";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
    searchText: {
        padding: "16px"
    },
    searchError: {
        fontSize: "10px",
        color: "red"
    },
    searchContainer: {
        padding: 16
    },
    squareBtn: {
        borderRadius: 0,
        padding: 0
    },
    "squareBtn:active:focus": {
        borderRadius: 0,
        padding: 0
    },
    closeBtn: {
        background: "#F3F3F3"
    }
});

class Search extends Component {
    state = {
        search: "",
        error: null
    };

    onSearch = (e) => {
        this.setState({
            search: e.target.value
        });
    };

    clearSearch = () => {
        this.setState({
            search: ""
        });
    };

    setSearch = (lat, lng) => {
        this.setState({
            search: lat.toFixed(6) + "," + lng.toFixed(6)
        });
    };

    _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            let re = /-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?/;
            if (this.state.search.match(re) && this.state.search.match(re)[0] === this.state.search.match(re).input) {
                //lat and long coords are good
                let lat = this.state.search.split(",")[0];
                let lon = this.state.search.split(",")[1];
                if (lon > 180 || lon < -180) {
                    this.setState({
                        error: "Longitude must be between -180 and 180 degrees"
                    });
                } else if (lat > 90 || lat < -90) {
                    this.setState({
                        error: "Latitude must be between -90 and 90 degrees"
                    });
                } else {
                    this.setState({ error: null });
                    this.props.flyTo({ x: lat, y: lon });
                }
            } else {
                this.setState({
                    error: 'Please enter latitude and longitude in the format "DD.DDDDD"'
                });
            }
        }
    };

    componentDidUpdate(prevProps) {
        if (this.state.mapLoaded) {
            return;
        }
        if (
            prevProps.mapState.clickPos.lng !== this.props.mapState.clickPos.lng ||
            prevProps.mapState.clickPos.lat !== this.props.mapState.clickPos.lat
        ) {
            this.setSearch(this.props.mapState.clickPos.lat, this.props.mapState.clickPos.lng);
            return;
        }
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                <div className="title">
                    <Typography variant="h6">Location Search</Typography>
                </div>
                <Divider />
                <div className={classes.searchContainer}>
                    <Input
                        onKeyDown={this._handleKeyDown}
                        id="full-width"
                        // inputProps={{ autoComplete: "off" }}
                        placeholder="Lat,Lon (ex: 12.34,56.78)"
                        value={this.state.search}
                        onChange={this.onSearch}
                        fullWidth
                        style={{ fontSize: 14 }}
                        endAdornment={
                            <InputAdornment position="end">
                                {this.state.search !== "" && (
                                    <IconButton
                                        className={
                                            classes.noPadButton + " " + classes.squareBtn + " " + classes.closeBtn
                                        }
                                        aria-label="toggle password visibility"
                                        onClick={this.clearSearch}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        }
                    />
                    {this.state.error && (
                        <Typography variant="body2" className={classes.searchError}>
                            {this.state.error}
                        </Typography>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mapState: state.map
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        flyTo: (location) => dispatch(mapActions.flyTo(location))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Search));
