import * as types from "../actions/actionTypes";
import Config from "../config";

const menuInit = {
    open: !Config.sidebarCollapsed
};

export function menuReducer(state = menuInit, action) {
    switch (action.type) {
        case types.TOGGLE_MENU_OPEN:
            return {
                ...state,
                open: !state.open
            };
        default:
            return state;
    }
}
