import * as types from "./actionTypes";

export function toggleLabel(layerId) {
    return dispatch => {
        dispatch({ type: types.TOGGLE_LEGEND_LABEL, result: layerId });
    };
}

export function showLabel(layerId) {
    return dispatch => {
        dispatch({ type: types.SHOW_LEGEND_LABEL, result: layerId });
    };
}

export function hideLabel(layerId) {
    return dispatch => {
        dispatch({ type: types.HIDE_LEGEND_LABEL, result: layerId });
    };
}
