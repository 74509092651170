import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const styles = (theme) => ({
    root: {
        position: "fixed",
        width: 29,
        height: 29,
        top: 186,
        right: 10,
        textAlign: "center",
        fontSize: "14px",
        fontWeight: "bold",
        borderRadius: 4,
        cursor: "pointer",
        display: "table",
        zIndex: 1
    },
    label: { display: "table-cell", verticalAlign: "top", padding: 6, textAlign: "right", fontSize: 9 },
    aerialIcon: {
        color: "white",
        borderColor: "white",
        backgroundImage: "url(media/aerial-map-logo.jpeg)"
    },
    streetIcon: {
        color: "black",
        borderColor: "black",
        backgroundImage: "url(media/normal-map-logo.png)"
    }
});

const ToggleBaseLayer = (props) => {
    let { classes } = props;
    let toggleClass = props.isaerial ? classes.aerialIcon : classes.streetIcon;
    return (
        <Tooltip
            key="toggleTT"
            title={props.isaerial ? "Toggle satellite basemap" : "Toggle street basemap"}
            placement="left"
        >
            <div className={toggleClass + " " + classes.root} onClick={props.toggle}></div>
        </Tooltip>
    );
};

export default withStyles(styles)(ToggleBaseLayer);
