import * as types from "../actions/actionTypes";

const layerSelectorInit = {
    maps: [],
    layerGroups: [],
    fetching: false
};

export function layerSelectorReducer(state = layerSelectorInit, action) {
    switch (action.type) {
        case types.FETCH_MAPS:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_MAPS_COMPLETE:
            return {
                ...state,
                maps: action.result
            };
        case types.SET_LAYER_GROUPS:
            return {
                ...state,
                layerGroups: action.result,
                fetching: false
            };
        case types.FETCH_MULTIPLE_STYLES:
            return {
                ...state,
                fetching: true
            };
        case types.FETCH_MULTIPLE_STYLES_COMPLETE:
            return {
                ...state,
                fetching: false
            };
        case types.MAP_ZOOMEND: {
            return {
                ...state,
                layerGroups: updateZoom(state.layerGroups, action.result)
            };
        }
        default:
            return state;
    }
}

function updateZoom(layerGroups, zoom) {
    let maps = layerGroups.map((map, index) => {
        map.layers.map((layer, index) => {
            layer.isShown =
                Math.floor(layer.minZoom) <= Math.floor(zoom) && Math.floor(zoom) <= Math.floor(layer.maxZoom);
            return layer;
        });
        return map;
    });
    return maps;
}
